import { Card, CardContent, CardMedia, Typography } from '@mui/material'; 
import React from 'react';

export default function CarouselCard({ item, key }) {
  return (
    <Card
      className="beforeafterCard"
      key={key}
      style={{ padding: 20, margin: 10, minHeight: '20rem', minWidth: '24rem' }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
        <div className="img-box text-center">
          <div className="d-flex justify-content-center">
            <CardMedia
              className="img-fluid"
              component="img"
              image={item?.before_image_url}
              alt={`Before image ${key + 1}`}
              style={{
                marginBottom: 10,
                height: '10rem',
                width: 'auto',
                maxHeight: '10rem',
                maxWidth: '10rem',
              }}
            />
          </div>
          <Typography variant="h4" className="white">
            Before image
          </Typography>
          <Typography variant="body2" color="white" style={{ marginTop: 8 }}>
            {item?.event_name} 
          </Typography>
        </div>
        <div className="img-box">
          <div className="d-flex justify-content-center">
            <CardMedia
              className="img-fluid"
              component="img"
              image={item?.after_image_url}
              alt={`After image ${key + 1}`}
              style={{
                marginBottom: 10,
                height: '10rem',
                width: 'auto',
                maxHeight: '10rem',
                maxWidth: '10rem',
              }}
            />
          </div>
          <Typography variant="h4" className="green">
            Transformed CTU image
          </Typography>
          
          <Typography variant="body2" color="white" style={{ marginTop: 8 }}>
            {item?.state_name}, {item?.ministry_name} 
          </Typography>
        </div>
      </div>
      
   
      
    </Card>
  );
}
