import axios from 'axios';
import { Box, Card, CardContent, CircularProgress, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import DiwaliBg from '../../assets/images/backgrounds/diwali_bg.png';
import DiwaliMobBg from '../../assets/images/backgrounds/diwali_mob_bg.png';
import Stats1 from '../../assets/images/backgrounds/stats1.png';
import Stats2 from '../../assets/images/backgrounds/stats2.png';
import Stats3 from '../../assets/images/backgrounds/stats3.png';
import Stats5 from '../../assets/images/backgrounds/stats5.png';
import { Carousel } from 'react-bootstrap';
import { totalCardParticipationCampaign } from '../../api';
const Diwali = () => {
  const [statsData, setStatsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await totalCardParticipationCampaign()
        const responseData = response.data;
        const dataByEventId = responseData.data.reduce((acc, item) => {
          acc[item.event_heading_id] = item;
          return acc;
        }, {});
        const formattedStats = [
          {
            title: 'Cleanliness Target Units',
            stat1: "No Of CTU's Transformed",
            stat2: 'Total Participation',
            img: Stats1,
            held: dataByEventId['6']?.event_completed || 0,
            participation: dataByEventId['6']?.total_participation || 0,
          },
          {
            title: 'RRR Centers',
            stat1: 'No Of RRR Centers Set Up',
            stat2: 'Total Volunteers',
            img: Stats2,
            held: dataByEventId['12']?.event_count || 0,
            participation: dataByEventId['12']?.total_participation || 0,
          },
          {
            title: 'General Cleanliness Drive',
            stat1: 'No Of Cleanliness Drive Held',
            stat2: 'Total Participation',
            img: Stats3,
            held: dataByEventId['13']?.event_count || 0,
            participation: dataByEventId['13']?.total_participation || 0,
          },
          {
            title: 'Light a Lamp Initiative',
            stat1: 'Total Event Count',
            stat2: 'Total Participation',
            img: Stats5,
            held: dataByEventId['20']?.event_count || 0,
            participation: dataByEventId['20']?.total_participation || 0,
          },
        ];
        console.log(formattedStats)
        setStatsData(formattedStats);
      } catch (error) {
        console.error('Error fetching campaign data:', error);
        setError(`Error fetching data: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <div>
      <img
        src={window.innerWidth > 768 ? DiwaliBg : DiwaliMobBg}
        alt="diwali"
        className="image-fluid"
        style={{
          width: '100%',
          height: 'auto',
          objectFit: 'cover',
        }}
      />
      <Box sx={{ padding: { xs: '5%', md: '2%' } }}>
        <Typography
          className="main-heading my-2"
          style={{
            whiteSpace: 'nowrap',
            fontWeight: 'bold',
            fontSize: '5vh',
            textAlign: 'center',
            color: '#3939A0',
            fontFamily: 'Work Sans, sans-serif !important',
            marginBottom: '4vh',
          }}
        >
          Campaign Highlights
        </Typography>
        {loading ? (
          <Box sx={{textAlign: "center"}}>
            <CircularProgress />
          </Box>
        ) : (
          <Carousel>
            {[0, 2].map((startIndex) => (
              <Carousel.Item key={startIndex}>
                <Grid container spacing={2} justifyContent="center">
                  {statsData.slice(startIndex, startIndex + 2).map((data, index) => (
                    <Grid item xs={12} md={6} key={index}>
                      <Card
                        sx={{
                          minHeight: '32vh',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          backgroundImage: `url(${data.img})`,
                          backgroundSize: 'cover',
                          backgroundPosition: 'center',
                          backgroundRepeat: 'no-repeat',
                        }}
                      >
                        <CardContent>
                          <Typography
                            variant="h4"
                            fontWeight="bold"
                            fontSize={{ xs: '1.8rem', md: '1.8rem' }}
                            color="white"
                            textAlign="center"
                            fontFamily="Work Sans, sans-serif !important"
                            marginBottom="3.4vh"
                          >
                            {data.title}
                          </Typography>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <Typography
                                variant="h6"
                                fontWeight="bold"
                                fontSize="1.2rem"
                                color="white"
                                marginBottom="2vh"
                                fontFamily="Work Sans, sans-serif !important"
                              >
                                {data.stat1}
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography
                                variant="h4"
                                fontWeight="bold"
                                fontSize={{ xs: '1.2rem', md: '1.2rem' }}
                                color="white"
                                fontFamily="Work Sans, sans-serif !important"
                                display="flex"
                                justifyContent="flex-end"
                              >
                                {data.held}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <Typography
                                variant="h6"
                                fontWeight="bold"
                                fontSize="1.2rem"
                                color="white"
                                fontFamily="Work Sans, sans-serif !important"
                              >
                                {data.stat2}
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography
                                variant="h4"
                                fontWeight="bold"
                                fontSize={{ xs: '1.2rem', md: '1.2rem' }}
                                color="white"
                                fontFamily="Work Sans, sans-serif !important"
                                display="flex"
                                justifyContent="flex-end"
                              >
                                {data.participation}
                              </Typography>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </Carousel.Item>
            ))}
          </Carousel>
        )}
      </Box>
    </div>
  );
};
export default Diwali;
