import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography, Card, CardContent, Button } from '@mui/material';
import styled from '@mui/material/styles/styled';
import Paper from '@mui/material/Paper';
import { useLocation, useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import TargetBg from '../../assets/images/backgrounds/targetbg.jpg';
import TargetBg1 from '../../assets/images/backgrounds/targetbg1.png';
import TargetBg2 from '../../assets/images/backgrounds/targetbg2.png';
import waterIcon from '../../assets/images/logos/water.png';
import binIcon from '../../assets/images/logos/bin.png';
import landIcon from '../../assets/images/logos/land.png';
import binAfter from '../../assets/images/logos/bin_after.png';
import landAfter from '../../assets/images/logos/land_after.png';
import airport from '../../assets/images/logos/airport.png';
import camera from '../../assets/images/logos/camera.png';
import education from '../../assets/images/logos/education.png';
import gaushala from '../../assets/images/logos/gaushala.png';
import medic from '../../assets/images/logos/medic.png';
import house from '../../assets/images/logos/house.png';
import market from '../../assets/images/logos/market.png';
import pray from '../../assets/images/logos/pray.png';
import road from '../../assets/images/logos/road-map.png';
import river from '../../assets/images/logos/river.png';
import slum from '../../assets/images/logos/slum.png';
import trainStation from '../../assets/images/logos/train-station.png';
import zoo from '../../assets/images/logos/zoo.png';
import work from '../../assets/images/logos/work.png';
import garbage from '../../assets/images/logos/50.png';
import people from '../../assets/images/logos/51.png';
import volunteer from '../../assets/images/logos/volunteer.png';
import community from '../../assets/images/logos/52.png';
import targetBar from '../../assets/images/logos/target_bar.png';
import CustomGaugeChart from '../gauge-chart';
import ProgressBarComponent from '../progress-bar';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { Chrono } from 'react-chrono';
import Timeline from '../timeline';
import { getCtuDateRange } from '../../api';
import { Loader } from 'feather-icons-react/build/IconComponents';

export default function TargetUnits({
  post15,

  ctuDatewise,
  ctuData,
  ctuVolunteerDatewise,
  totalVolunteers,
}) {
  const { t } = useTranslation();
  const { location } = useLocation();
  const screenWidth = window.innerWidth;
  const [items, setItems] = useState([]);
  const [items1, setItems1] = useState([]);
  const [progressPercentage, setProgressPercentage] = useState(0);
  const [progressPercentage1, setProgressPercentage1] = useState(0);

  const logoMapping = [
    { icon: airport, area: 'Airports and surrounding areas' },
    { icon: camera, area: 'Tourist locations' },
    { icon: education, area: 'Areas surrounding schools and colleges' },
    { icon: gaushala, area: 'Gaushalas' },
    { icon: house, area: 'Residential areas' },
    { icon: market, area: 'Market spaces' },
    { icon: medic, area: 'Health Institutions and Adjacent Areas' },
    { icon: pray, area: 'Places of worship' },
    { icon: road, area: 'Roadsides (NH, SH, Local)' },
    { icon: river, area: 'Waterbodies' },
    { icon: slum, area: 'Slums' },
    { icon: trainStation, area: 'Railway tracks and stations' },
    { icon: work, area: 'Areas surrounding public & private offices' },
    { icon: zoo, area: 'Zoos and wildlife areas' },
    { icon: trainStation, area: 'Bus stands and toll plaza' },
  ];

  const getIconByAreaType = (areaType) => {
    const match = logoMapping.find((logo) => logo.area === areaType);
    return match ? match.icon : landIcon; // Default to landIcon if no match is found
  };
  // useEffect(() => {
  //   if (!ctuDatewise || ctuDatewise.length === 0) return;

  //   // Step 1: Map all dates from ctuDatewise
  //   const transformedItems = ctuDatewise.map((item) => {
  //     return {
  //       title: item?.event_date?.value, // Use the date as the title for each step
  //       date_num: item?.event_date?.value?.split('-').pop(), // Extract the day part of the date
  //       cardTitle: item?.ctu_event_closed_count !== undefined ? item.ctu_event_closed_count : null, // Show the count for each date
  //       visible: item?.visible,
  //     };
  //   });

  //   // Step 2: Find the last index where visibility is 'show'
  //   const lastVisibleIndex = ctuDatewise
  //     .map((item, index) => (item?.visible ? index : -1))
  //     .filter((index) => index !== -1) // Remove non-visible dates
  //     .pop(); // Get the last visible date index

  //   // Step 3: Calculate progress percentage based on the last 'show' date
  //   // The progress should be calculated based on the number of steps, not the entire bar length.
  //   const progressPercentage =
  //     lastVisibleIndex !== undefined ? (lastVisibleIndex / (ctuDatewise.length - 1)) * 100 : 0;

  //   // Step 4: Update state with all items and calculated progress percentage
  //   setItems(transformedItems);
  //   setProgressPercentage(progressPercentage);
  // }, [ctuDatewise]);

  useEffect(() => {
    if (!ctuDatewise || ctuDatewise.length === 0) return;

    let cumulativeClosedEvents = 0; // Initialize cumulative closed events

    // Step 1: Map all dates from ctuDatewise
    const transformedItems = ctuDatewise.map((item) => {
      if (item?.visible && item?.ctu_event_closed_count !== undefined) {
        cumulativeClosedEvents += item?.ctu_event_closed_count; // Add to cumulative sum only if visible
      }

      return {
        title: item?.event_date?.value, // Use the date as the title for each step
        date_num: item?.event_date?.value?.split('-').pop(), // Extract the day part of the date
        cardTitle: item?.visible
          ? cumulativeClosedEvents // Display cumulative count of closed events if visible
          : null,
        visible: item?.visible,
      };
    });

    // Step 2: Find the last index where visibility is 'show'
    const lastVisibleIndex = ctuDatewise
      .map((item, index) => (item?.visible ? index : -1))
      .filter((index) => index !== -1) // Remove non-visible dates
      .pop(); // Get the last visible date index

    // Step 3: Calculate progress percentage based on the last 'show' date
    // The progress should be calculated based on the number of steps, not the entire bar length.
    const progressPercentage =
      lastVisibleIndex !== undefined ? (lastVisibleIndex / (ctuDatewise.length - 1)) * 100 : 0;

    // Step 4: Update state with all items and calculated progress percentage
    setItems(transformedItems);
    setProgressPercentage(progressPercentage);
  }, [ctuDatewise]);

  useEffect(() => {
    if (!ctuVolunteerDatewise || ctuVolunteerDatewise.length === 0) return;

    let cumulativeVolunteers = 0; // Initialize cumulative volunteers

    // Step 1: Map all dates from ctuVolunteerDatewise
    const transformedItems = ctuVolunteerDatewise.map((item) => {
      if (item?.visible && item?.no_of_volunteers_joined !== undefined) {
        cumulativeVolunteers += item?.no_of_volunteers_joined; // Add to cumulative sum only if visible
      }

      return {
        title: item?.event_date?.value, // Use the date as the title for each step
        date_num: item?.event_date?.value?.split('-').pop(), // Extract the day part of the date
        cardTitle: item?.visible
          ? cumulativeVolunteers // Display cumulative count of volunteers if visible
          : null,
        visible: item?.visible,
      };
    });

    // Step 2: Find the last index where visibility is 'show'
    const lastVisibleIndex = ctuVolunteerDatewise
      .map((item, index) => (item?.visible ? index : -1))
      .filter((index) => index !== -1) // Remove non-visible dates
      .pop(); // Get the last visible date index

    // Step 3: Calculate progress percentage based on the last 'show' date
    // The progress should be calculated based on the number of steps, not the entire bar length.
    const progressPercentage =
      lastVisibleIndex !== undefined
        ? (lastVisibleIndex / (ctuVolunteerDatewise.length - 1)) * 100
        : 0;

    // Step 4: Update state with all items and calculated progress percentage
    setItems1(transformedItems);
    setProgressPercentage1(progressPercentage);
  }, [ctuVolunteerDatewise]);

  // useEffect(() => {
  //   if (!ctuVolunteerDatewise || ctuVolunteerDatewise.length === 0) return;

  //   // Step 1: Map all dates from ctuVolunteerDatewise
  //   const transformedItems = ctuVolunteerDatewise.map((item) => {
  //     return {
  //       title: item?.event_date?.value, // Use the date as the title for each step
  //       date_num: item?.event_date?.value?.split('-').pop(), // Extract the day part of the date
  //       cardTitle:
  //         item?.no_of_volunteers_joined !== undefined ? item.no_of_volunteers_joined : null, // Show the count for each date
  //       visible: item?.visible,
  //     };
  //   });

  //   // Step 2: Find the last index where visibility is 'show'
  //   const lastVisibleIndex = ctuVolunteerDatewise
  //     .map((item, index) => (item?.visible ? index : -1))
  //     .filter((index) => index !== -1) // Remove non-visible dates
  //     .pop(); // Get the last visible date index

  //   // Step 3: Calculate progress percentage based on the last 'show' date
  //   // The progress should be calculated based on the number of steps, not the entire bar length.
  //   const progressPercentage =
  //     lastVisibleIndex !== undefined
  //       ? (lastVisibleIndex / (ctuVolunteerDatewise.length - 1)) * 100
  //       : 0;

  //   // Step 4: Update state with all items and calculated progress percentage
  //   setItems1(transformedItems);
  //   setProgressPercentage1(progressPercentage);
  // }, [ctuVolunteerDatewise]);

  return (
    <Box
      sx={{
        width: '100%',
        margin: 'auto',
        textAlign: 'center',
      }}
    >
      <Grid
        container
        spacing={2}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          backgroundImage: `url(${TargetBg})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          minHeight: '90vh',
          paddingBottom: '50px',
        }}
      >
        <Grid item xs={12}>
          <Box
            sx={{
              width: '80%',
              textAlign: 'center',
              zIndex: 2,
              margin: '40px auto 40px',
            }}
          >
            <Typography className="main-heading">{t('cleaniness_target_units')}</Typography>
            <Grid item xs={12}>
              <Typography variant="h1" className="sub-para" mt={2}>
                A Cleanliness Target Unit (CTU) refers to severely neglected, high-risk areas like
                garbage points or dumpsites that pose significant environmental and health risks,
                often overlooked during routine cleanliness drives in various regions.
              </Typography>
            </Grid>
          </Box>
          <Grid item xs={12} sx={{ color: '#2F42D1' }}>
            <Typography variant="p" className="bar_uppertext">
              CTUs Transformed
            </Typography>
          </Grid>

          <Grid container alignItems="center" justifyContent="center">
            {items.length > 0 ? (
              <Grid item xs={11} sm={12} sx={{ marginBottom: '30px' }}>
                {screenWidth > 800 ? (
                  <ProgressBarComponent items={items} progressPercentage={progressPercentage} />
                ) : (
                  <Timeline items={items} />
                )}
              </Grid>
            ) : (
              <Grid item xs={12} sx={{ marginBottom: '30px' }}>
                <Loader size={50} color="#2F42D1" />
              </Grid>
            )}
          </Grid>
          <br />
          <br />

          <Grid item xs={12} sx={{ color: '#2F42D1' }}>
            <Typography variant="p" className="bar_uppertext">
              Daily Volunteer Engagement
            </Typography>
          </Grid>

          <Grid container alignItems="center" justifyContent="center">
            {items1.length > 0 ? (
              <Grid item xs={11} sm={12} sx={{ marginBottom: '30px' }}>
                {screenWidth > 800 ? (
                  <ProgressBarComponent items={items1} progressPercentage={progressPercentage1} />
                ) : (
                  <Timeline items={items1} />
                )}
              </Grid>
            ) : (
              <Grid item xs={12} sx={{ marginBottom: '30px' }}>
                <Loader size={50} color="#2F42D1" />
              </Grid>
            )}
          </Grid>
        </Grid>

        {/* <Grid
          container
          spacing={2}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Grid
            item
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Grid
              item
              xs={4}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'end',
              }}
            >
              <img className="img-fluid clean_logo" src={volunteer} alt="target" />
            </Grid>
            <Grid
              item
              xs={8}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'start',
                height: '20vh',
              }}
            >
              <Box
                sx={{
                  textAlign: 'start',
                  ml: 2,
                }}
              >
                <Typography variant="h3" className="target_units" sx={{ color: '#3C7A16' }}>
                  {totalVolunteers && totalVolunteers}
                  
                </Typography>
                <Typography variant="p" className="target_units_sub">
                  Total CTU volunteers
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid> */}
      </Grid>
      <Box
        sx={{
          width: '100%',
          height: '18px',
          backgroundColor: '#34894F',
        }}
      />
    </Box>
  );
}
