import React, { useEffect, useState, useRef } from 'react';
import {
  CardContent,
  Grid,
  Card,
  Box,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
} from '@mui/material';
import PageContainer from '../../components/container/PageContainer';
import { useNavigate, useParams } from 'react-router-dom';
import backgroundImage from '../../assets/images/backgrounds/publicPageBg.jpg';
import Banner1 from '../../assets/images/banner/banner1.png';
import Banner2 from '../../assets/images/banner/banner2.png';
import Banner3 from '../../assets/images/banner/banner3.png';
import Footer from '../../layouts/full-layout/footer/Footer';
import MediaGallery from '../../components/media-gallery';
import { useTranslation } from 'react-i18next';
import { Formik, Form, Field } from 'formik';
import { getEventDetails, getMediaImages, getPresignedUrl, getPresignedUrlRead } from '../../api';
import { useSnackbar } from 'notistack';

import {
  getAllStates,
  getDistrictsByStateCode,
  getULBsByDistrictCode,
  getBlocksByDistrictCode,
  getGPsByBlockCode,
  getVillagesByGPCode,
} from '../../firebase/functions';
import { useDispatch, useSelector } from 'react-redux';
import { t } from '../../i18n';
import * as Yup from 'yup';
import CustomTextField from '../../components/forms/custom-elements/CustomTextField';
import CustomSelect from '../../components/forms/custom-elements/CustomSelect';
import CustomFormLabel from '../../components/forms/custom-elements/CustomFormLabel';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
import ImagePreview from '../../components/image-preview';
import Certificate from '../../components/certificate';
import GoogleMapButton from '../../components/google-map-btn';

const validationSchema = Yup.object().shape({
  user_name: Yup.string().required('Name is required'),
  age: Yup.string()
    .required('Field is required')
    .matches(/^\d+$/, 'Field must be a positive integer')
    .max(2, 'Field cannot exceed 2 digits')
    .test('not-zero', 'Field cannot be 0', (value) => value !== '0'),
  gender: Yup.string().required('Gender is required'),
  phone: Yup.string()
    .required('Phone is required')
    .matches(/^[1-9]\d{9}$/, 'Number must be exactly 10 digits and cannot start with 0'),
  location_type: Yup.string().required('Location is required'),

  state_name: Yup.string().required('State Name is required'),

  district_name: Yup.string().required('District Name is required'),
  ulb_name: Yup.string().when('location_type', {
    is: 'URBAN',
    then: () => Yup.string().required('ULB name is required'),
    otherwise: () => Yup.string(),
  }),
  block_name: Yup.string().when('location_type', {
    is: 'RURAL',
    then: () => Yup.string().required('Block name is required'),
    otherwise: () => Yup.string(),
  }),
  gp_name: Yup.string().when('location_type', {
    is: 'RURAL',
    then: () => Yup.string().required('GP name is required'),
    otherwise: () => Yup.string(),
  }),
  village_name: Yup.string().when('location_type', {
    is: 'RURAL',
    then: () => Yup.string().required('Village name is required'),
    otherwise: () => Yup.string(),
  }),
});

const event_theme = [
  {
    event_theme_id: 1,
    event_theme_name: 'Swachhata Mein Jan Bhaagidari"',
    bannerUrl: Banner1,
    theme: 'teal',
    footerUrl: '/asset/footer/footer2.png',
  },
  {
    event_theme_id: 2,
    event_theme_name: 'Cleanliness Target Units (Swachhata Lakshit Ekayi)',
    bannerUrl: Banner2,
    theme: 'green',
    footerUrl: '/asset/footer/footer3.png',
  },
  {
    event_theme_id: 3,
    event_theme_name: 'SafaiMitra Suraksha Shivirs"',
    bannerUrl: Banner3,
    theme: 'red',
    footerUrl: '/asset/footer/footer1.png',
  },
];

const EventType = () => {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const [username, setUsername] = useState('');
  const [imgUrl, setImgURL] = useState('');
  const [event, setEvent] = useState();
  const [eventTheme, setEventTheme] = useState();
  const [images, setImages] = useState([]);
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [ulbs, setULBs] = useState([]);
  const [blocks, setBlocks] = useState([]);
  const [gps, setGPs] = useState([]);
  const [villages, setVillages] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openCertificate, setOpenCertificate] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const formikRef = useRef(null);
  const imageRef = useRef(null);

  const keysToDisplay = [
    'event_theme_name',
    'event_heading_name',
    'event_name',
    'event_start_date',
    'event_end_date',
    'event_coordinator_name',
    'event_coordinator_number',
    'number_of_expected_participants',
    'number_of_waste_art_created',
    'number_of_waste_art_installed',
    'sport_activity_installed',
    'awareness_topic_covered',
    'resources_to_be_used',
    'area_type',
    'garbage_type',
    'cleaning_start_date',
    'cleaning_end_date',
    'start_date',
    'end_date',
    'ministry_name',
    'ministry_department_name',
    'ministry_unit_name',
    'location_type',
    'state_name',
    'district_name',
    'ulb_name',
    'block_name',
    'gp_name',
    'latitude',
    'longitude',
    'after_image_file',
  ];

  const formatKey = (str) => {
    return str
      .replace(/_/g, ' ') // Replace underscores with spaces
      .replace(/\b\w/g, (match) => match.toUpperCase()); // Capitalize the first letter of each word
  };

  const initialValues = {
    user_name: '',
    age: '',
    gender: '',
    phone: '',
    location_type: '',
    state_name: '',
    state_code: '',
    district_name: '',
    district_code: '',
    ulb_name: '',
    ulb_code: '',
    gp_name: '',
    gp_code: '',
    village_name: '',
    village_code: '',
    before_image_file: null,
  };

  useEffect(() => {
    getStates();
  }, []);

  useEffect(() => {
    // Fetch event details based on event ID
    const fetchData = async () => {
      const data = await getEventDetails(id); // Fetch event details

      setEvent(data?.data?.event); // Set event data

      if (data?.data?.media?.length > 0) {
        const media = data?.data?.media[0]?.media_url;

        // Extract folder name
        const folderName = media.substring(1, media.lastIndexOf('/'));

        // Extract file name
        const fileName = media.substring(media.lastIndexOf('/') + 1);
        const presignedUrl = await getPresignedUrlRead(fileName, folderName);

        setImgURL(presignedUrl?.data);
      }

      // Find and set the matching theme based on event_theme_id
      const matchingTheme = event_theme.find(
        (theme) => theme.event_theme_id == data?.data?.event?.event_theme_id,
      );
      setEventTheme(matchingTheme); // Set event theme if found

      // console.log('Event:', data.data);
      // console.log('Theme:', matchingTheme);
    };

    fetchData();
  }, [id]);

  const getStates = async () => {
    const statesData = await getAllStates();
    setStates(
      statesData
        ?.map((o) => {
          return { label: o.state_name, value: o.state_code };
        })
        .sort((a, b) => a.label.localeCompare(b.label)),
    );
  };

  const getStateDistricts = async (id) => {
    const districtData = await getDistrictsByStateCode(id);
    setDistricts(
      districtData
        ?.map((o) => {
          return { label: o.district_name, value: o.district_code };
        })
        .sort((a, b) => a.label.localeCompare(b.label)),
    );
  };

  const getDistrictULBs = async (id) => {
    const ulbsData = await getULBsByDistrictCode(id);
    setULBs(
      ulbsData
        ?.map((o) => {
          return { label: o.ulb_name, value: o.ulb_code };
        })
        .sort((a, b) => a.label.localeCompare(b.label)),
    );
  };

  const getDistrictBlocks = async (id) => {
    const blocksData = await getBlocksByDistrictCode(id);
    setBlocks(
      blocksData
        ?.map((o) => {
          return { label: o.block_name, value: o.block_code };
        })
        .sort((a, b) => a.label.localeCompare(b.label)),
    );
  };

  const getGPs = async (id) => {
    const gpsData = await getGPsByBlockCode(id);
    setGPs(
      gpsData
        ?.map((o) => {
          return { label: o.gp_name, value: o.gp_code };
        })
        .sort((a, b) => a.label.localeCompare(b.label)),
    );
  };

  const getVillages = async (id) => {
    const villagesData = await getVillagesByGPCode(id);
    setVillages(
      villagesData
        ?.map((o) => {
          return { label: o.village_name, value: o.village_code };
        })
        .sort((a, b) => a.label.localeCompare(b.label)),
    );
  };

  const handleStateChange = (value, setFieldValue) => {
    const state = states.find((obj) => obj.value === value);
    if (state.value) {
      setFieldValue('state_name', state.label);
      setFieldValue('state_code', state.value);
      setFieldValue('district_name', '');
      setFieldValue('district_code', '');
      setFieldValue('block_name', '');
      setFieldValue('block_code', '');
      setFieldValue('gp_code', '');
      setFieldValue('gp_name', '');
      setFieldValue('village_code', '');
      setFieldValue('village_name', '');
      setFieldValue('ulb_name', '');
      setFieldValue('ulb_code', '');
      getStateDistricts(state.value);
    }
  };

  const handleDistrictChange = (value, setFieldValue, values) => {
    const district = districts.find((obj) => obj.value === value);
    if (district.value) {
      setFieldValue('district_name', district.label);
      setFieldValue('district_code', district.value);
      setFieldValue('block_name', '');
      setFieldValue('block_code', '');
      setFieldValue('gp_code', '');
      setFieldValue('gp_name', '');
      setFieldValue('village_code', '');
      setFieldValue('village_name', '');
      setFieldValue('ulb_name', '');
      setFieldValue('ulb_code', '');
      if (values.location_type === 'URBAN') {
        getDistrictULBs(district.value);
      } else {
        getDistrictBlocks(district.value);
      }
    }
  };

  const handleBlockChange = (value, setFieldValue) => {
    const block = blocks.find((obj) => obj.value === value);
    if (block.value) {
      setFieldValue('block_name', block.label);
      setFieldValue('block_code', block.value);
      setFieldValue('gp_code', '');
      setFieldValue('gp_name', '');
      setFieldValue('village_code', '');
      setFieldValue('village_name', '');
      setFieldValue('ulb_name', '');
      setFieldValue('ulb_code', '');
      getGPs(block.value);
    }
  };

  const handleGPChange = (value, setFieldValue) => {
    const gp = gps.find((obj) => obj.value === value);
    if (gp.value) {
      setFieldValue('gp_name', gp.label);
      setFieldValue('gp_code', gp.value);
      setFieldValue('village_code', '');
      setFieldValue('village_name', '');
      setFieldValue('ulb_name', '');
      setFieldValue('ulb_code', '');
      getVillages(gp.value);
    }
  };

  const handleVillageChange = (value, setFieldValue) => {
    const village = villages.find((obj) => obj.value === value);
    if (village.value) {
      setFieldValue('village_name', village.label);
      setFieldValue('village_code', village.value);
      setFieldValue('ulb_name', '');
      setFieldValue('ulb_code', '');
    }
  };

  const handleUlbChange = (value, setFieldValue) => {
    const ulb = ulbs.find((obj) => obj.value === value);
    if (ulb.value) {
      setFieldValue('ulb_name', ulb.label);
      setFieldValue('ulb_code', ulb.value);
      setFieldValue('gp_code', '');
      setFieldValue('gp_name', '');
      setFieldValue('village_code', '');
      setFieldValue('village_name', '');
    }
  };

  const getImages = async () => {
    try {
      const imagesData = await getMediaImages(1, 10);
      const imageData = imagesData?.data?.data;

      if (imageData) {
        setImages((prevImages) => [...prevImages, ...imageData]);
      }
      // setPage((prevPage) => prevPage + 1);
    } catch (error) {
      console.error('Error fetching images:', error);
    }
  };

  const uploadImageToPresignedUrl = async (file) => {
    const fileExtension = file.name.split('.').pop();
    const uniqueFileName = `${file.name.split('.')[0]}_${Date.now()}.${fileExtension}`;
    const folderName = 'event_images';

    const uniqueFile = new File([file], uniqueFileName, { type: file.type });
    const presignedUrl = await getPresignedUrl(uniqueFileName, folderName);
    if (presignedUrl.status !== 200) {
      enqueueSnackbar('Failed to upload image', {
        autoHideDuration: 6000,
        variant: 'error',
      });
      return;
    }
    const response = await axios.put(presignedUrl.data, uniqueFile, {
      headers: { 'Content-Type': file.type },
    });

    if (response.status === 200) {
      return `${folderName}/${uniqueFileName}`;
    } else {
      throw new Error('Failed to upload image');
    }
  };
  useEffect(() => {
    getImages();
  }, []);
  const handleOpenModal = () => {
    setOpenModal(true); // Open the modal when button is clicked
  };

  const handleCloseModal = () => {
    setOpenModal(false); // Close the modal
  };

  const handleOpenCertificateModal = () => {
    setOpenCertificate(true); // Open the modal when button is clicked
  };

  const handleCloseCertificateModal = () => {
    setOpenCertificate(false); // Close the modal
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      setUsername(values.user_name);
      setLoading(true); // Set form submission state to true

      // Check if the image file is present
      if (values.before_image_file) {
        // console.log('Uploading image...');
        const imageUrl = await uploadImageToPresignedUrl(values.before_image_file);
        // console.log('Image uploaded successfully:', imageUrl);

        // Prepare the API payload with imageUrl and form values
        const payload = {
          media_url: imageUrl,
          media_type: 'PUBLIC',
          upload_date: new Date().toISOString().split('T')[0], // YYYY-MM-DD
          media_category: 'image',
          name: values.user_name,
          age: parseInt(values.age, 10),
          gender: values.gender,
          phone: values.phone,
          location_type: values.location_type,
          event_id: event.event_id,
          citizen_state_name: values.state_name,
          citizen_state_code: values.state_code,
          citizen_district_name: values.district_name,
          citizen_district_code: values.district_code,
          citizen_gp_name: values.gp_name,
          citizen_gp_code: values.gp_code,
          citizen_village_name: values.village_name,
          citizen_village_code: values.village_code,
          citizen_block_name: values.block_name,
          citizen_block_code: values.block_code,
          citizen_ulb_name: values.ulb_name,
          citizen_ulb_code: values.ulb_code,
          upload_time: new Date().toLocaleTimeString('en-GB', { hour12: false }), // HH:MM:SS
        };

        // console.log('Payload prepared for submission:', payload); // Log the payload to check its structure

        // Submit the payload to the API
        const response = await axios.post(
          'https://asia-south1-dashakswachhataka.cloudfunctions.net/createMediaEventV1',
          payload,
        ); // Replace with actual endpoint

        if (response.status === 200) {
          console.log('Data successfully submitted');
          enqueueSnackbar('Data successfully submitted', {
            autoHideDuration: 6000,
            variant: 'success',
          });
          formikRef.current.resetForm(); // Reset the form after successful submission
          handleCloseModal(); // Close the modal after successful submission
          handleOpenCertificateModal();
        } else {
          console.log('Error in submission', response.status);
        }
      } else {
        console.log('No image file selected');
      }
    } catch (error) {
      console.error('Error occurred during submission:', error);
    } finally {
      setSubmitting(false); // Set form submission state back to false
    }
  };

  return (
    <>
      {event && eventTheme ? (
        <div
          style={{
            backgroundImage: `url(${eventTheme?.bannerUrl})`,
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
            width: '100%',
            // height: '100%',
            minHeight: '100vh',
            backgroundSize: 'cover',
          }}
        >
          <PageContainer event_theme_name="Starter Page" description="this is Starter Page">
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                overflowX: 'hidden',
                position: 'relative',
                marginTop: '70px',
                height: '100%',
              }}
            >
              <Grid container spacing={0}>
                <Box
                  sx={{
                    width: '100%',
                    height: 'auto',
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {/* <img
                    src={eventTheme?.bannerUrl}
                    style={{ width: 'auto', height: '50vh' }}
                    alt="banner"
                  /> */}

                  {/* Event Name centered */}
                  {/* <Typography
                    variant="h1"
                    sx={{
                      position: 'absolute',
                      color: 'white',
                      fontWeight: 'bold',
                      textShadow: '2px 2px 8px rgba(0, 0, 0, 0.7)',
                      textAlign: 'center',
                    }}
                  >
                    {event.event_name}
                  </Typography> */}

                  {/* <Button
                    variant="contained"
                    onClick={handleOpenModal}
                    sx={{
                      bottom: '15%',
                      border: `2px solid ${eventTheme.theme}`,
                      color: eventTheme.theme,
                      textTransform: 'uppercase',
                      borderRadius: '50px',
                      backgroundColor: 'white',
                      fontWeight: 'bold',
                      position: 'absolute',
                    }}
                  >
                    {t('contribute')}
                  </Button> */}
                </Box>

                <Box
                  sx={{
                    width: '80%',
                    margin: 'auto',
                    textAlign: 'center',
                    mb: 3,
                    zIndex: 2,
                    // marginTop: '5%',
                  }}
                >
                  <Typography variant="h1" mt={3} color="white">
                    {event.event_name}
                  </Typography>
                  <Card>
                    <CardContent>
                      <Grid container spacing={2}>
                        {keysToDisplay.map((o) => {
                          return (
                            <>
                              {event[o] && (
                                <Grid item xs={12} sm={3}>
                                  <Typography variant="subtitle2" color="textSecondary">
                                    {formatKey(o)}
                                  </Typography>
                                  <Typography variant="body1" style={{ wordBreak: 'break-all' }}>
                                    {event[o]}
                                  </Typography>
                                </Grid>
                              )}
                            </>
                          );
                        })}

                        <Grid item xs={12}>
                          <GoogleMapButton
                            targetLat={event?.latitude}
                            targetLng={event?.longitude}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>

                  {/* <MediaGallery images={images} /> */}

                  {imgUrl && (
                    <Box
                      sx={{
                        width: '80%',
                        margin: 'auto',
                        textAlign: 'center',
                        mb: 3,
                        zIndex: 2,
                        marginTop: '5%',
                        justifyContent: 'center',
                      }}
                    >
                      <Typography variant="h1" mt={3} color="white">
                        Event Before Image
                      </Typography>
                      <Card className="d-flex justify-content-center">
                        <CardContent>
                          <Grid container spacing={2} textAlign={'center'} alignItems={'center'}>
                            <img width="auto" height={'300px'} alt="before" src={imgUrl} />
                          </Grid>
                        </CardContent>
                      </Card>

                      {/* <MediaGallery images={images} /> */}
                    </Box>
                  )}
                </Box>
              </Grid>
            </Box>
            {/* <Footer imgUrl={eventTheme.footerUrl} /> */}
          </PageContainer>

          <Dialog open={openModal} onClose={handleCloseModal}>
            <DialogTitle>
              <h2>{t('Contribute to Event')}</h2>
            </DialogTitle>
            <DialogContent>
              <Formik
                innerRef={formikRef}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ errors, touched, values, setFieldValue, handleChange, isSubmitting }) => {
                  return (
                    <Form>
                      {/* <h5>User Details</h5> */}
                      <Grid container spacing={3} mb={4}>
                        {/* Name */}
                        <Grid item xs={12} md={3}>
                          <CustomFormLabel>Name*</CustomFormLabel>
                          <Field
                            as={CustomTextField}
                            name="user_name"
                            autoComplete="off"
                            type="text"
                            fullWidth
                            error={
                              touched.user_name && errors.user_name && <div>{errors.user_name}</div>
                            }
                            helperText={touched.user_name && errors.user_name}
                          />
                        </Grid>

                        {/* Age */}
                        <Grid item xs={12} md={3}>
                          <CustomFormLabel>Age*</CustomFormLabel>
                          <Field
                            as={CustomTextField}
                            name="age"
                            autoComplete="off"
                            type="number"
                            fullWidth
                            error={touched.age && !!errors.age}
                            helperText={touched.age && errors.age}
                          />
                        </Grid>

                        {/* Gender */}
                        <Grid item xs={12} md={3}>
                          <CustomFormLabel>Gender*</CustomFormLabel>
                          <CustomSelect
                            value={values.gender}
                            onChange={handleChange}
                            id="gender"
                            name="gender"
                            fullWidth
                            size="small"
                          >
                            <MenuItem value="Male">Male</MenuItem>
                            <MenuItem value="Female">Female</MenuItem>
                            <MenuItem value="Other">Other</MenuItem>
                          </CustomSelect>
                          {errors.gender && touched.gender ? (
                            <div className="error-message">{errors.gender}</div>
                          ) : null}
                        </Grid>

                        {/* Phone */}
                        <Grid item xs={12} md={3}>
                          <CustomFormLabel>Phone*</CustomFormLabel>
                          <Field
                            as={CustomTextField}
                            name="phone"
                            autoComplete="off"
                            type="text"
                            fullWidth
                            error={touched.phone && !!errors.phone}
                            helperText={touched.phone && errors.phone}
                          />
                        </Grid>

                        {/* Location Type */}
                        <Grid item xs={12} md={3}>
                          <CustomFormLabel>Location Type*</CustomFormLabel>
                          <CustomSelect
                            value={values.location_type}
                            onChange={handleChange}
                            id="location_type"
                            name="location_type"
                            fullWidth
                            size="small"
                          >
                            <MenuItem value="RURAL">RURAL</MenuItem>
                            <MenuItem value="URBAN">URBAN</MenuItem>
                          </CustomSelect>
                          {errors.location_type && touched.location_type ? (
                            <div className="error-message">{errors.location_type}</div>
                          ) : null}
                        </Grid>

                        {/* State */}
                        <Grid item xs={12} md={3}>
                          <CustomFormLabel>State*</CustomFormLabel>
                          <CustomSelect
                            value={values.state}
                            onChange={(e) => handleStateChange(e.target.value, setFieldValue)}
                            id="state"
                            name="state"
                            fullWidth
                            size="small"
                          >
                            {states.map((data) => (
                              <MenuItem value={data.value} key={data.value}>
                                {data.label}
                              </MenuItem>
                            ))}
                          </CustomSelect>
                          {errors.state && touched.state ? (
                            <div className="error-message">{errors.state}</div>
                          ) : null}
                        </Grid>

                        {/* District */}
                        <Grid item xs={12} md={3}>
                          <CustomFormLabel>District*</CustomFormLabel>
                          <CustomSelect
                            value={values.district}
                            onChange={(e) =>
                              handleDistrictChange(e.target.value, setFieldValue, values)
                            }
                            id="district"
                            name="district"
                            fullWidth
                            size="small"
                          >
                            {districts.map((data) => (
                              <MenuItem value={data.value} key={data.value}>
                                {data.label}
                              </MenuItem>
                            ))}
                          </CustomSelect>
                          {errors.district && touched.district ? (
                            <div className="error-message">{errors.district}</div>
                          ) : null}
                        </Grid>

                        {/* ULB/Block/GP/Village */}
                        {values.location_type === 'URBAN' && ulbs.length > 0 && (
                          <Grid item xs={12} md={3}>
                            <CustomFormLabel>ULB Name*</CustomFormLabel>
                            <CustomSelect
                              value={values.ulb_code}
                              onChange={(e) => handleUlbChange(e.target.value, setFieldValue)}
                              id="ulb_code"
                              name="ulb_code"
                              fullWidth
                              size="small"
                            >
                              {ulbs.map((data) => (
                                <MenuItem value={data.value} key={data.value}>
                                  {data.label}
                                </MenuItem>
                              ))}
                            </CustomSelect>
                            {errors.ulb_code && touched.ulb_code ? (
                              <div className="error-message">{errors.ulb_code}</div>
                            ) : null}
                          </Grid>
                        )}

                        {values.location_type === 'RURAL' && blocks.length > 0 && (
                          <Grid item xs={12} md={3}>
                            <CustomFormLabel>Block Name*</CustomFormLabel>
                            <CustomSelect
                              value={values.block_code}
                              onChange={(e) => handleBlockChange(e.target.value, setFieldValue)}
                              id="block_code"
                              name="block_code"
                              fullWidth
                              size="small"
                            >
                              {blocks.map((data) => (
                                <MenuItem value={data.value} key={data.value}>
                                  {data.label}
                                </MenuItem>
                              ))}
                            </CustomSelect>
                            {errors.block_code && touched.block_code ? (
                              <div className="error-message">{errors.block_code}</div>
                            ) : null}
                          </Grid>
                        )}

                        {values.location_type === 'RURAL' && gps.length > 0 && (
                          <Grid item xs={12} md={3}>
                            <CustomFormLabel>GP Name*</CustomFormLabel>
                            <CustomSelect
                              value={values.gp_code}
                              onChange={(e) => handleGPChange(e.target.value, setFieldValue)}
                              id="gp_code"
                              name="gp_code"
                              fullWidth
                              size="small"
                            >
                              {gps.map((data) => (
                                <MenuItem value={data.value} key={data.value}>
                                  {data.label}
                                </MenuItem>
                              ))}
                            </CustomSelect>
                            {errors.gp_code && touched.gp_code ? (
                              <div className="error-message">{errors.gp_code}</div>
                            ) : null}
                          </Grid>
                        )}

                        {values.location_type === 'RURAL' && villages.length > 0 && (
                          <Grid item xs={12} md={3}>
                            <CustomFormLabel>Village Name*</CustomFormLabel>
                            <CustomSelect
                              value={values.village_code}
                              onChange={(e) => handleVillageChange(e.target.value, setFieldValue)}
                              id="village_code"
                              name="village_code"
                              fullWidth
                              size="small"
                            >
                              {villages.map((data) => (
                                <MenuItem value={data.value} key={data.value}>
                                  {data.label}
                                </MenuItem>
                              ))}
                            </CustomSelect>
                            {errors.village_code && touched.village_code ? (
                              <div className="error-message">{errors.village_code}</div>
                            ) : null}
                          </Grid>
                        )}
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <Box
                          sx={{
                            border: '2px dashed grey',
                            borderRadius: '4px',
                            p: 2,
                            textAlign: 'center',
                            cursor: 'pointer',
                            position: 'relative',
                            marginTop: '3%',
                          }}
                          onClick={() => imageRef.current.click()}
                        >
                          {values.before_image_file ? (
                            <Typography>{values.before_image_file.name}</Typography>
                          ) : (
                            <Typography>Upload Image</Typography>
                          )}
                        </Box>
                        <input
                          ref={imageRef}
                          type="file"
                          accept=".jpg,.png"
                          style={{ display: 'none' }}
                          onChange={(event) => {
                            const file = event.currentTarget.files[0];
                            setFieldValue('before_image_file', file);
                          }}
                        />
                        {touched.before_image_file && errors.before_image_file && (
                          <div style={{ color: 'red' }}>{errors.before_image_file}</div>
                        )}
                        <ImagePreview file={values.before_image_file} />
                      </Grid>

                      <Grid container justifyContent="center" sx={{ mt: 4 }}>
                        <Grid item>
                          <LoadingButton
                            loading={isSubmitting}
                            color="primary"
                            variant="contained"
                            type="submit"
                            size="large"
                          >
                            Submit
                          </LoadingButton>
                        </Grid>
                      </Grid>
                    </Form>
                  );
                }}
              </Formik>
            </DialogContent>
          </Dialog>

          <Dialog open={openCertificate} onClose={handleCloseCertificateModal}>
            <DialogTitle></DialogTitle>
            <DialogContent>
              <Certificate
                username={username}
                handleCloseCertificateModal={handleCloseCertificateModal}
              />
            </DialogContent>
          </Dialog>
        </div>
      ) : (
        <p>Loading event...</p>
      )}
    </>
  );
};
export default EventType;
