import React from 'react';
import './ticker.css';

const Ticker = () => {
  const handleTickerClick = () => {
    window.open(`${process.env.PUBLIC_URL}/SD_SD_guidelines.pdf`, '_blank');
  };

  return (
    <div className="ticker-container">
      <div className="static-text" onClick={handleTickerClick}>
        Guidelines for Swachh Diwali Shubh Diwali 2024 | स्वच्छ दिवाली शुभ दिवाली 2024
      </div>
    </div>
  );
};

export default Ticker;

