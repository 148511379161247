import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import sbdLogo from '../../../assets/images/logos/sbd_logo.png';
import fbLogo from '../../../assets/images/logos/fb.png';
import twitterLogo from '../../../assets/images/logos/twitter.png';
import instaLogo from '../../../assets/images/logos/insta.png';
import ytLogo from '../../../assets/images/logos/youtube.png';
import { formatIndianNumber } from '../../../utils/helper';

const Footer = ({ imgUrl, visitorCount }) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  return (
    <div
      className="container-fluid"
      style={{
        textAlign: 'center',

        backgroundImage: `url(${imgUrl})`,
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        height: screenWidth < 500 ? '50vh' : '60vh',
      }}
    >
      <div
        className="row d-flex align-items-center justify-content-center"
        style={{ height: '100%', paddingTop: '8%', color: 'white' }}
      >
        <div className="col-lg-6 col-md-6 col-sm-12">
          <div className="row d-flex align-items-center justify-content-center">
            <div className="col-6 text-center" style={{ width: 'auto' }}>
              <h4 className="text-start">Help and Support</h4>
              {/* <p className="text-start">swachhtaKaDashak.com</p> */}
              <p className="text-start">011-408-46952</p>
              {/* <div className="text-start">
                <h4>
                  Visitors : <strong> {formatIndianNumber(visitorCount?.visitor_counts)}</strong>
                </h4>
                <p className="text-start">
                  Last Updated :{' '}
                  {new Date(visitorCount?.updated_at).toLocaleString('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                    hour12: true, // Use 12-hour format
                  })}
                </p>
              </div> */}
              {/* <div className="d-flex text-start">
                <div className="mx-1">
                  <img src={fbLogo} alt="fb" />
                </div>
                <div className="mx-1">
                  <img src={twitterLogo} alt="twitter" />
                </div>
                <div className="mx-1">
                  <img src={instaLogo} alt="instagram" />
                </div>
                <div className="mx-1">
                  <img src={ytLogo} alt="yt" />
                </div>
              </div> */}
            </div>
            {/* <div className="col-6 text-center" style={{ width: 'auto' }}>
             <h4 className="text-start">Quick Links</h4>
             <p className="text-start">Home</p>
             <p className="text-start">About Us</p>
             <p className="text-start">Participate</p>
             <p className="text-start">Media Gallery</p>
           </div> */}
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12">
          <img
            className="img-fluid"
            src={sbdLogo}
            alt="footer"
            style={{ height: screenWidth < 500 ? '15vh' : '30vh' }}
          />
        </div>
      </div>
      <div className="row" style={{ backgroundColor: '#FF9700', color: '#000' }}>
        <Typography variant="body2" style={{ color: 'white' }}>
          © Swachhata Hi Seva 2024. All rights reserved.
        </Typography>
      </div>
    </div>
  );
};

export default Footer;
