import React, { useEffect, useState } from 'react';
import Navbar from '../../layouts/full-layout/header/homeHeader';
import { Box, Button, Link, Typography } from '@mui/material';
import { getAllPartners } from '../../firebase/functions';
import { t } from 'i18next';
import { useNavigate } from 'react-router';

export default function Partners() {
  const [partners, setPartners] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const getPartners = async () => {
      setLoading(true);
      const partnersData = await getAllPartners();

      const sortedPartners = partnersData.sort((a, b) =>
        a.organisation_name.localeCompare(b.organisation_name)
      );
      setPartners(sortedPartners);
      console.log(sortedPartners);
      setLoading(false);
    };
    getPartners();
  }, []);

  const handleNavigate = () => {
    navigate('/partner-signup');
  };

  return (
    <>
      <Navbar />

      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          overflowX: 'hidden',
          position: 'relative',
          paddingTop: '100px',
        }}
      >
        <Typography className="main-heading" variant="h1">
          {t('partners')}
        </Typography>
        <Button variant="contained" onClick={handleNavigate}>
          Signup as Partner
        </Button>
        {!loading && (
          <ul>
            {partners.length > 0 ? (
              partners.map((partner) => (
                <li key={partner.id}>
                  <p className="target_units_sub">
                    {partner.organisation_name} ({partner.organisation_type})
                  </p>
                </li>
              ))
            ) : (
              <h1>No partners has been approved yet...</h1>
            )}
          </ul>
        )}
      </Box>
    </>
  );
}
