import { combineReducers } from 'redux';
import CustomizerReducer from './customizer/CustomizerReducer';
import ProfileReducer from './profile/ProfileReducer';
import RoleReducer from './profile/RoleReducer';

const RootReducers = combineReducers({
  CustomizerReducer,
  ProfileReducer,
  RoleReducer,
});

export default RootReducers;
