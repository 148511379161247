import React, { useEffect, useState } from 'react';
import 'react-step-progress-bar/styles.css';
import { ProgressBar, Step } from 'react-step-progress-bar';
import { formatIndianNumber } from '../../utils/helper';

const ProgressBarComponent = ({ items, progressPercentage }) => {
  return (
    <div style={{ width: '80%', margin: 'auto', marginTop: '20px', position: 'relative' }}>
      <ProgressBar
        filledBackground="#2938a9"
        unfilledBackground="#b8c0ff"
        percent={progressPercentage} // Progress bar filled up to the last present date
        height={30}
      >
        {items.map((item, index) => (
          <Step key={index}>
            {({ accomplished }) => (
              <div
                className={`indexedStep ${accomplished ? 'accomplished' : ''}`}
                style={{
                  cursor: item.cardTitle !== null ? 'pointer' : 'not-allowed', // Only make clickable if accomplished
                  fontSize: '12px',
                  //   marginTop: '-15px',
                  textAlign: 'center',
                  position: 'relative',
                  // color: item.cardTitle !== null ? '#000' : '#ccc', // Grey out if not accomplished
                }}
              >
                {item?.date_num}
                <sup>
                  {item?.date_num[1] == 1
                    ? 'st'
                    : item?.date_num[1] == 2
                    ? 'nd'
                    : item?.date_num[1] == 3
                    ? 'rd'
                    : 'th'}
                </sup>

                {/* Show cardTitle only if it exists */}
                {item.cardTitle !== null && (
                  <div
                    style={{
                      position: 'absolute',
                      top: '40px',
                      left: '50%',
                      transform: 'translateX(-50%)',
                    }}
                  >
                    {item?.visible && (
                      <p style={{ fontWeight: 'bold', fontSize: '16px' }}>
                        {formatIndianNumber(item.cardTitle)}
                      </p>
                    )}
                  </div>
                )}
              </div>
            )}
          </Step>
        ))}
      </ProgressBar>
    </div>
  );
};

export default ProgressBarComponent;
