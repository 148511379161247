import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import Navbar from '../../layouts/full-layout/header/homeHeader';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import {
  getUserByID,
  getPermissionsByRoleID,
  getUserByUserName,
  getCurrentToken,
  getAllStates,
  getMediaImages,
} from '../../firebase/functions';
import EventType from '../../components/event-type';
import { useDispatch, useSelector } from 'react-redux';
import { updateProfile, updateRole } from '../../redux/profile/action';
import firebase from '../../firebase';
import MapComponent from '../../components/mapComponent';
import TargetUnits from '../../components/target-units';
import {
  getLandingPagePostData1,
  getLandingPagePostData2,
  getLandingPagePostData3,
  getVisitorCount,
  getPresignedUrlRead,
} from '../../api';
import EventPlanned from '../../components/event-planned';
import '../../index'

const MainEvent = () => {
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState('');
  const [selectedStateName, setSelectedStateName] = useState('');
  const [post15, setPost15] = useState(true);
  const [mapCount, setMapCount] = useState({
    total_participants: 0,
    total_media: 0,
    total_ministries: 0,
    states: [],
    ctu_identified: [],
  });
  const [topCtuIdentified, setTopCtuIdentified] = useState([]);
  const [topCtuAreawise, setTopCtuAreawise] = useState([]);
  const [topGarbageCleaned, setTopGarbageCleaned] = useState([]);
  const [ctuDatewise, setCtuDatewise] = useState([]);
  const [ctuVolunteerDatewise, setCtuVolunteerDatewise] = useState([]);
  const [landingPageData, setLandingPageData] = useState([]);
  const [ctuIdentified, setCtuIdentified] = useState();
  const [ctuCleaned, setCtuCleaned] = useState();
  const [totalVolunteers, setTotalVolunteers] = useState();
  const [ctuData, setCtuData] = useState([]);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [leaderboardData, setLeaderBoardData] = useState([]);
  const [eventHighlights, setEventHighlights] = useState({
    art_waste: 0,
    cultural_fest: 0,
    trees_planted: 0,
    food_streets: 0,
  });

  const [cleaninessDrive, setCleaninessDrive] = useState();

  const [massCleanlinessDrive, setMassCleanlinessDrive] = useState();
  const [visitorCount, setVisitorCount] = useState({
    visitor_counts: 0,
    updated_at: '',
  });

  const [bigNumbers, setBigNumbers] = useState({
    ctu_identified_count: 0,
    ongoing_ctu_activities: 0,
    ctu_cleaned_count: 0,
  });

  const [campaignHighlights, setCampaignHighlights] = useState({
    total_events_planned: 0,
    total_events_completed: 0,
    total_public_participation: 0,
  });

  const [campaignCtuData, setCampaignCtuData] = useState({
    event_created: 0,
    event_closed: 0,
    total_participation: 0,
  });

  const [campaignMassData, setCampaignMassData] = useState({
    event_created: 0,
    event_closed: 0,
    total_participation: 0,
  });

  const [campaignJanData, setCampaignJanData] = useState({
    event_created: 0,
    event_closed: 0,
    total_participation: 0,
  });

  const [campaignShivir, setCampaignShivir] = useState({
    event_created: 0,
    event_closed: 0,
    total_participation: 0,
  });

  const { logged_in } = useSelector((state) => state.ProfileReducer);

  const getStates = async () => {
    const statesData = await getAllStates();

    setStates(
      statesData
        ?.map((o) => {
          return { label: o.state_name, value: o.state_code };
        })
        .sort((a, b) => a.label.localeCompare(b.label)),
    );
  };

  useEffect(() => {
    getStates();
    const count = localStorage.getItem('visitor_count');
    if (count) {
      setVisitorCount({
        visitor_counts: count,
        updated_at: new Date().toISOString(),
      });
    } else {
      getVisitorCount().then((res) => {
        localStorage.setItem('visitor_count', res.data?.visitor_counts);
        setVisitorCount({
          visitor_counts: res?.data?.visitor_counts || 0,
          updated_at: res?.data?.updated_at || '',
        });
      });
    }
  }, []);

  useEffect(() => {
    const getImages = async () => {
      try {
        const imagesData = await getMediaImages('landing_page_images');
        if (imagesData) {
          setImages((prevImages) => [...prevImages, ...imagesData]);

          const mediaURLs = await Promise.all(
            imagesData?.map(async (mediaItem) => {
              const media = mediaItem?.image_url;
              // Extract folder name
              const folderName = media.substring(0, media.lastIndexOf('/'));

              // Extract file name
              const fileName = media.substring(media.lastIndexOf('/') + 1);

              const presignedUrl = await getPresignedUrlRead(fileName, folderName);
              return presignedUrl?.data;
            }),
          );
          const validMediaURLs = mediaURLs.filter((url) => url !== undefined && url !== null);
          setImages(validMediaURLs); // Set imgURL as an array of URLs
        }
      } catch (error) {
        console.error('Error fetching images:', error);
      }
    };

    getImages();
  }, []);

  const getUserDetails = async (e) => {
    const userRecord = await getUserByID(e);
    const data = await getPermissionsByRoleID(userRecord?.role_id);
    const userDetails = await getUserByUserName(userRecord?.user_name);
    const token = await getCurrentToken();
    dispatch(updateRole({ info: data }));
    dispatch(
      updateProfile({
        info: {
          user_record: userRecord,
          user_details: userDetails,
        },
        user_role: userRecord?.user_role,
        logged_in: true,
        token: token,
      }),
    );
    localStorage.setItem('token', token);
  };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const data = await getEventCardDetails();
  //     setEventData(data); // save the data in the state
  //   };

  //   fetchData();
  // }, []);

  useEffect(() => {
    if (!logged_in) {
      firebase?.auth().onAuthStateChanged(async (res) => {
        if (res) {
          await getUserDetails(res?.uid);
        }
      });
    }
  }, []);

  useEffect(() => {
    const fetchLandingPageData = async () => {
      try {
        // Fetch landing page data 1
        const res1 = await getLandingPagePostData1(); //getDashboardCtuDataAndOtherV1

        if (res1 && res1?.data) {
          const query1Data = res1?.data?.query1Data?.[0] || {};
          const query3Data = res1?.data?.query3Data?.[0] || {};
          const query4Data = res1?.data?.query4Data?.[0] || {};
          const query5Data = res1?.data?.query5Data?.[0] || {};

          // Set landing page data
          setLandingPageData(res1?.data);
          setCtuCleaned(query1Data?.ctu_cleaned_count || 0);
          setCtuIdentified(query1Data?.ctu_identified_count || 0);
          setTotalVolunteers(query1Data?.total_volunteers_count || 0);
          setCtuData(query3Data);
          setEventHighlights({
            ...eventHighlights,
            art_waste: query5Data?.total_waste_art_installed || 0,
            trees_planted: query4Data?.total_trees_planted || 0,
          });
          setCleaninessDrive(query5Data?.total_area_cleaned || 0);

          // Set big numbers
          setBigNumbers((prevBigNumbers) => ({
            ...prevBigNumbers,
            ctu_identified_count: query1Data?.ctu_identified_count || 0,
            ctu_cleaned_count: query1Data?.ctu_cleaned_count || 0,
          }));

          // Create identified and cleaned data arrays dynamically
          const stateWiseCounts = query1Data?.state_wise_counts || [];
          const identifiedData = stateWiseCounts.map((item) => ({
            state_name: item.state_name,
            event_count: item.identified_count,
            state_code: item.state_code,
          }));

          const cleanedData = stateWiseCounts.map((item) => ({
            state_name: item.state_name,
            event_count: item.cleaned_count,
            state_code: item.state_code,
          }));

          setMapCount((prevMapCount) => ({
            ...prevMapCount,
            ctu_identified: identifiedData?.sort((a, b) => b.event_count - a.event_count),
            ctu_cleaned: cleanedData?.sort((a, b) => b.event_count - a.event_count),
          }));

          // Set datewise CTU data
          setCtuDatewise(res1?.data?.query2Data || []);

          // Set top CTU identified
          setTopCtuIdentified(res1?.data?.top_leaderboard_ctu || []);

          // Set leaderboard data (top 5 states)
          const topStates = [...stateWiseCounts]
            .sort((a, b) => b.cleaned_count - a.cleaned_count)
            .slice(0, 5); // Taking the top 5 states
          setLeaderBoardData(topStates);
        }

        // Fetch landing page data 2
        const res2 = await getLandingPagePostData2(); //getLandingPageCtuDataAndOtherV1

        if (res2 && res2?.data) {
          // Set mass cleanliness drive data
          setMassCleanlinessDrive(
            res2?.data?.query2Data?.[0]?.event_count_mass_scale_cleanliness_drives || 0,
          );

          setEventHighlights((prevEventHighlights) => ({
            ...prevEventHighlights,
            cultural_fest: res2?.data?.query4Data?.[0]?.swachh_bharat_cultural_fests || 0,
            food_streets: res2?.data?.query4Data?.[0]?.swachh_food_streets_initiative || 0,
          }));

          setCtuVolunteerDatewise(res2?.data?.query3Data || []);

          // Set big numbers for ongoing CTU activities
          setBigNumbers((prevBigNumbers) => ({
            ...prevBigNumbers,
            ongoing_ctu_activities: res2?.data?.query1Data?.[0]?.ongoing_ctu_activities || 0,
          }));
        }

        // Fetch landing page data 3
        const res3 = await getLandingPagePostData3();

        if (res3 && res3?.data) {
          // Set top 4 CTU areawise
          const topCtuAreawise = res3?.data?.event_count_area_type
            .sort((a, b) => b.event_count_area_type - a.event_count_area_type)
            .slice(0, 4);
          setTopCtuAreawise(topCtuAreawise);

          // Set top garbage cleaned
          setTopGarbageCleaned(res3?.data?.top_garbage_cleaned || []);

          // Set campaign highlights
          setCampaignHighlights({
            total_events_planned: res3?.data.total_events_counts[0]?.total_events_counts || 0,
            total_events_completed:
              res3?.data.total_event_completed_counts[0]?.total_event_completed_counts || 0,
            total_public_participation:
              res3?.data?.total_public_participation_1[0]?.total_public_participation_1 +
                res3?.data?.total_public_participation_2[0]?.total_public_participation_2 +
                res3?.data?.total_public_participation_3[0]?.total_public_participation_3 || 0,
          });

          setCampaignCtuData({
            event_created: res3?.data?.total_event_counts_ctu[0]?.total_event_counts_ctu || 0,
            event_closed:
              res3?.data?.total_event_completed_counts_ctu[0]?.total_event_completed_counts_ctu ||
              0,
            total_participation:
              res3?.data?.total_participation_ctu[0]?.total_participation_ctu || 0,
          });

          setCampaignMassData({
            event_created: res3?.data?.total_event_counts_mass[0]?.total_event_counts_mass || 0,
            event_closed:
              res3?.data?.total_completed_event_counts_mass[0]?.total_completed_event_counts_mass ||
              0,
            total_participation:
              res3?.data?.total_public_participation_mass[0]?.total_public_participation_mass || 0,
          });

          setCampaignJanData({
            event_created: res3?.data?.total_event_counts_jan[0]?.total_event_counts_jan || 0,
            event_closed:
              res3?.data?.total_completed_event_counts_jan[0]?.total_completed_event_counts_jan ||
              0,
            total_participation:
              res3?.data?.total_public_participation_jan[0]?.total_public_participation_jan || 0,
          });

          setCampaignShivir({
            event_created: res3?.data?.total_event_counts_shivir[0]?.total_event_counts_shivir || 0,
            event_closed:
              res3?.data?.total_completed_event_counts_shivir[0]
                ?.total_completed_event_counts_shivir || 0,
            total_participation:
              res3?.data?.total_public_participation_shivir[0]?.total_public_participation_shivir ||
              0,
          });

          // Set datewise CTU volunteer data
          setCtuVolunteerDatewise(query3Data);
        }
      } catch (error) {
        console.error('Error fetching landing page data:', error);
      }
    };

    fetchLandingPageData();
  }, []); // No dependencies, runs only on mount

  return ( 
    
    <div style={{ width: '100%', height: '100%' }}>
      
      <Navbar />
        
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          overflowX: 'hidden',
          position: 'relative',
          marginTop: '70px',
        }}
      >
        <div
          style={{
            width: '100%',
            height: '2px',
            backgroundColor: '#FF9A00',
          }}
        />
      <h2 className='main-heading'>Swachhata Hi Seva 2024</h2>
        <MapComponent
          topCtuAreawise={topCtuAreawise}
          selectedState={selectedState}
          setSelectedState={setSelectedState}
          setSelectedStateName={setSelectedStateName}
          selectedStateName={selectedStateName}
          states={states}
          mapCount={mapCount}
          post15={post15}
          topCtuIdentified={topCtuIdentified}
          leaderboardData={leaderboardData}
          topGarbageCleaned={topGarbageCleaned}
          ctuIdentified={ctuIdentified}
          ctuCleaned={ctuCleaned}
          bigNumbers={bigNumbers}
        />

        <div
          style={{
            width: '100%',
            height: '2px',
            backgroundColor: '#34894F',
            margin: '20px 0',
          }}
        />
        <TargetUnits
          post15={post15}
          ctuDatewise={ctuDatewise}
          ctuData={ctuData}
          ctuVolunteerDatewise={ctuVolunteerDatewise}
          totalVolunteers={totalVolunteers}
        />


        <EventPlanned eventHighlights={eventHighlights} />

      </Box>
    </div>
  );
};

export default MainEvent;
