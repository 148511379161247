import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import NewBannerEnglish from '../../assets/newBanner_english.jpg';
import NewBannerHindi from '../../assets/newBanner_hindi.jpg';

export default function LandingCarousel({ bannerData }) {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [bg, setBg] = useState(NewBannerEnglish);

  const { i18n } = useTranslation();

  const getBgImage = () => {
    if (i18n.language === 'en') {
      setBg(NewBannerEnglish);
    } else if (i18n.language === 'hi') {
      setBg(NewBannerHindi);
    }
  };

  useEffect(() => {
    getBgImage();
  }, [i18n.language]); // Add i18n.language as the dependency, not i18n itself

  return (
    <>
      <Carousel>
        <Carousel.Item>
          <img src={bg} className="img-fluid" alt="banner" style={{ minWidth: '100vw' }} />
        </Carousel.Item>
      </Carousel>
    </>
  );
}
