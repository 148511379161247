export const formatIndianNumber = (num) => {
  // Convert number to string
  let numStr = num.toString();

  // Split the number into integer and fractional parts
  let [integerPart, decimalPart] = numStr.split('.');

  // Add commas according to the Indian number system
  let lastThreeDigits = integerPart.slice(-3);
  let otherDigits = integerPart.slice(0, -3);

  if (otherDigits !== '') {
    lastThreeDigits = ',' + lastThreeDigits;
  }

  // Regular expression to match every pair of digits
  otherDigits = otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ',');

  // Combine both parts
  let formattedNumber = otherDigits + lastThreeDigits;

  // Append decimal part if it exists
  if (decimalPart) {
    formattedNumber += '.' + decimalPart;
  }

  return formattedNumber;
};
