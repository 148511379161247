import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // Import Lightbox styles
import styled from 'styled-components';
import { Col } from 'react-bootstrap';
import GandhiJi from '../../assets/images/logos/gandhijiLogo.png';
import Logo from '../../assets/images/logos/logo.png';
import { bool } from 'prop-types';
import { useTranslation } from 'react-i18next';

const MediaGallery = ({ images }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const openLightbox = (index) => {
    setPhotoIndex(index);
    setIsOpen(true);
  };

  const closeLightbox = () => {
    setIsOpen(false);
  };

  const handleKeyDown = (event, index) => {
    if (event.key === 'Enter' || event.key === ' ') {
      openLightbox(index);
    }
  };

  // console.log('images', images);
  return (
    <Box
      sx={{
        paddingTop: '5%',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      <Typography
        variant="h1"
        className="main-heading my-2"
        style={{
          whiteSpace: 'nowrap',
          fontWeight: '700',
          fontFamily: 'Roboto',
          textAlign: 'center',
          color: '#3939A0',
        }}
      >
        {t('media_gallery')}
      </Typography>
      <Grid
        container
        sx={{
          padding: '5%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {images?.map((data, index) => (
          <Col
            xs={6}
            lg={3}
            className="hashtagImg_sm"
            style={{ display: 'inline-block' }}
            key={Math.random()}
          >
            <div
              style={{
                borderRadius: '15px',
                position: 'relative',
                overflow: 'hidden',
                margin: '10px',
              }}
              onClick={() => {
                openLightbox(index);
              }}
            >
              <VillageImageDiv
                style={{
                  backgroundColor: '#fff',
                  borderRadius: '15px',
                  backgroundSize: 'cover',
                  filter: 'blur(40px)',
                  height: '15vh',
                  // border: '1px solid black',
                  overflow: 'hidden',
                  backgroundImage: `url(${data})`,
                  cursor: 'pointer',
                }}
              />
              <VillageImageDiv
                className="th-border-top th-border-bottom"
                style={{
                  borderRadius: '15px',
                  position: 'absolute',
                  top: '0%',
                  right: '0%',
                  // border: '2px solid transparent',
                  bottom: '0%',
                  backgroundSize: 'contain',
                  backgroundImage: `url(${data})`,
                  cursor: 'pointer',
                }}
              />

              <div
                style={{
                  position: 'absolute',
                  left: '0',
                  right: '0',
                  bottom: '0',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: 'rgba(0,0,0,0.5)',
                  color: '#fff',
                  fontSize: '1.5rem',
                  fontWeight: 'bold',
                  cursor: 'pointer',
                }}
              >
                <Typography variant="h6" sx={{ color: '#fff', fontWeight: '700' }}>
                  {data?.event_heading_name}
                </Typography>
              </div>
            </div>
          </Col>
        ))}
      </Grid>

      {isOpen && (
        <Box>
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={closeLightbox}
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + images.length - 1) % images.length)
            }
            onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
          />
          <CloseIcon />
        </Box>
      )}
    </Box>
  );
};

export default MediaGallery;

export const VillageImageDiv = styled.div`
  background: url(image.png);
  width: 100%;
  padding-bottom: 50%;
  background-size: contain;
  background-repeat: no-repeat !important;
  background-color: '#555';
  background-position: center;
  border-radius: 20px;
`;
