import { UPDATE_ROLE_DETAILS } from '../constants';

const INIT_STATE = {
  info: {},
};

const RoleReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_ROLE_DETAILS:
      return {
        ...state,
        ...action.value,
      };
    default:
      return state;
  }
};

export default RoleReducer;
