import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography, Card, CardContent } from '@mui/material';
import ThemeOneBg from '../../assets/images/backgrounds/blue.png';
import ThemeThreeBg from '../../assets/images/backgrounds/green.png';
import ThemeFourBg from '../../assets/images/backgrounds/orange.png';
import { getDiwasCard } from '../../api';

const DiwasCard = ({ bgImage, upperText, upperTitle, lowerText, lowerTitle, cardTitle }) => (
  <Grid item xs={12} sm={6} md={4}>
    <Card
      sx={{
        height: '32vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        backgroundImage: `url(${bgImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <CardContent>
        <Typography
          variant="h2"
          fontWeight="bold"
          fontSize="1.5rem"
          color="white"
          gutterBottom
          sx={{ marginTop: '-20px' }}
        >
          {upperText}
        </Typography>
        <Typography variant="h2" fontWeight="bold" fontSize="1.2rem" color="white">
          {upperTitle}
        </Typography>

        <Typography
          fontSize="1.5rem"
          color="white"
          variant="h2"
          fontWeight="bold"
          sx={{ marginTop: '40px' }}
        >
          {lowerText}
        </Typography>

        <Typography variant="h2" fontWeight="bold" fontSize="1.2rem" color="white">
          {lowerTitle}
        </Typography>
      </CardContent>
    </Card>
    <Typography variant="h3" fontWeight="bold" sx={{ textAlign: 'center', marginTop: '10px' }}>
      {cardTitle}
    </Typography>
  </Grid>
);

export default function Diwas() {
  const [cardData, setCardData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getDiwasCard();
        if (res.status === 200) {
          setCardData(res.data.data[0]);
        }
      } catch (err) {
        console.error(err);
      }
    };
    fetchData();
  }, []);

  const {
    ctu_total_event_completed,
    ctu_total_participants_count,
    jan_bhagidaari_total_event_completed,
    jan_bhagidaari_total_participants_count,
    shivir_total_event_completed,
    shivir_total_participants_count,
  } = cardData;

  return (
    <Box sx={{ width: '100%', margin: 'auto', textAlign: 'center' }}>
      <Grid
        container
        spacing={2}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          minHeight: '60vh',
          paddingTop: '20px',
          marginBottom: '30px',
        }}
      >
        <Grid item xs={12}>
          <Typography className="main-heading">Swachh Bharat Diwas 2024</Typography>
        </Grid>

        <Grid
          container
          spacing={3}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '0 4%',
          }}
        >
          <DiwasCard
            bgImage={ThemeFourBg}
            upperText={ctu_total_event_completed}
            upperTitle="CTUs Transformed"
            lowerText={ctu_total_participants_count}
            lowerTitle="Volunteers Participated"
            cardTitle="Cleanliness Target Unit"
          />

          <DiwasCard
            bgImage={ThemeOneBg}
            upperText={jan_bhagidaari_total_event_completed}
            upperTitle="Activities Organized"
            lowerText={jan_bhagidaari_total_participants_count}
            lowerTitle="Volunteers Joined"
            cardTitle="Swachhata Mai Jan Bhagidari"
          />

          <DiwasCard
            bgImage={ThemeThreeBg}
            upperText={shivir_total_event_completed}
            upperTitle="Shivirs Organized"
            lowerText={shivir_total_participants_count}
            lowerTitle="Volunteers Joined"
            cardTitle="Safai Mitra Suraksha Shivir"
          />
        </Grid>
      </Grid>
    </Box>
  );
}
