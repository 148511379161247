import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Button, Card, CardContent, CardMedia, Typography } from '@mui/material';
import TargetBg from '../../assets/images/backgrounds/targetbg.jpg';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import CarouselCard from '../../components/cardcarousel';
import { getPresignedUrlRead } from '../../api';
import { Row, Container, Col } from 'react-bootstrap';
import PageContainer from '../../components/container/PageContainer';

export default function BeforeAfterGallery() {
  const { t } = useTranslation();
  const screenSize = window.innerWidth;
  const [beforeAfterImages, setBeforeAfterImages] = useState([]);
  const [page, setPage] = useState(1);
  const limit = 20;

  const fetchBeforeAfterImages = async (page, limit) => {
    const response = await fetch(
      `https://asia-south1-dashakswachhataka.cloudfunctions.net/getEventMediaBeforeAndAfterImages?page=${page}&limit=${limit}`,
    );
    if (!response.ok) {
      throw new Error('Failed to fetch data');
    }
    return await response.json();
  };

  useEffect(() => {
    const getImages = async () => {
      try {
        // Fetch the data from the API
        const data = await fetchBeforeAfterImages(page, limit);

        if (data && data.data) {
          // Process the data to get presigned URLs
          const updatedImages = await Promise.all(
            data.data?.map(async (item) => {
              const beforeImageUrl = item?.before_image_url;
              const afterImageUrl = item?.after_image_url;

              // Extract folder and file names for 'before' image
              const beforeFolderName = beforeImageUrl.substring(1, beforeImageUrl.lastIndexOf('/'));
              const beforeFileName = beforeImageUrl.substring(beforeImageUrl.lastIndexOf('/') + 1);

              // Extract folder and file names for 'after' image
              const afterFolderName = afterImageUrl.substring(1, afterImageUrl.lastIndexOf('/'));
              const afterFileName = afterImageUrl.substring(afterImageUrl.lastIndexOf('/') + 1);

              // Fetch presigned URLs
              const beforePresignedUrl = await getPresignedUrlRead(
                beforeFileName,
                beforeFolderName,
              );

              // console.log('Before presigned URL:', beforePresignedUrl);
              const afterPresignedUrl = await getPresignedUrlRead(afterFileName, afterFolderName);
              // console.log('After presigned URL:', afterPresignedUrl);
              return {
                ...item,
                before_image_url: beforePresignedUrl.data,
                after_image_url: afterPresignedUrl.data,
              };
            }),
          );

          // Append new images to the existing list for pagination
          setBeforeAfterImages((prevImages) => [...prevImages, ...updatedImages]);
        }
      } catch (error) {
        console.error('Error fetching before and after images:', error);
      }
    };

    getImages();
  }, [page]); // Rerun the effect when `page` changes to fetch new data

  // Add a simple handler to load more data (if needed for pagination)
  const handleLoadMore = () => {
    setPage((prevPage) => prevPage + 1);
  };
  return (
    <PageContainer
      title="Transformation in Cleanliness Target Units"
      description="Transformation in Cleanliness Target Units"
    >
      <div
        style={{
          width: '100%',
          margin: 'auto',
          backgroundImage: `url(${TargetBg})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          paddingBottom: '50px',
          paddingTop: '100px',
          justifyContent: 'center',
        }}
      >
        <Typography className="main-heading text-center">
          Transformation in Cleanliness Target Units
        </Typography>
        <Container>
          <Row>
            {beforeAfterImages?.map((item, index) => (
              <Col xs={12} md={6} lg={6} className="text-center">
                <CarouselCard key={index} item={item} />
              </Col>
            ))}
          </Row>
        </Container>
        {/* Optional load more button for pagination */}
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <Button variant="contained" color="primary" onClick={handleLoadMore}>
            Load More
          </Button>
        </div>
      </div>
    </PageContainer>
  );
}
