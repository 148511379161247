import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import FlagBg from '../../assets/images/backgrounds/flag.gif';
import FlagBg from '../../assets/images/backgrounds/poster2.png';
import PhoneFlagBg from '../../assets/images/backgrounds/phone_poster.png';
import Leaderboard from '../../assets/images/content/leaderboard.png';
import Leaderboard1 from '../../assets/images/content/leaderboard1.png';
import GoldIcon from '../../assets/images/logos/gold.png';
import SilverIcon from '../../assets/images/logos/silver.png';
import BronzeIcon from '../../assets/images/logos/bronze.png';
import Map from '../../views/home/Map';
import { useLocation, useParams } from 'react-router';
import { Typography, Grid, Box } from '@mui/material';
import { Card } from 'react-bootstrap';
import { Loader } from 'feather-icons-react/build/IconComponents';

import waterIcon from '../../assets/images/logos/water.png';
import binIcon from '../../assets/images/logos/bin.png';
import landIcon from '../../assets/images/logos/land.png';
import binAfter from '../../assets/images/logos/bin_after.png';
import landAfter from '../../assets/images/logos/land_after.png';
import airport from '../../assets/images/logos/airport.png';
import camera from '../../assets/images/logos/camera.png';
import education from '../../assets/images/logos/education.png';
import gaushala from '../../assets/images/logos/gaushala.png';
import medic from '../../assets/images/logos/medic.png';
import house from '../../assets/images/logos/house.png';
import market from '../../assets/images/logos/market.png';
import pray from '../../assets/images/logos/pray.png';
import road from '../../assets/images/logos/road-map.png';
import river from '../../assets/images/logos/river.png';
import slum from '../../assets/images/logos/slum.png';
import trainStation from '../../assets/images/logos/train-station.png';
import zoo from '../../assets/images/logos/zoo.png';
import work from '../../assets/images/logos/work.png';
import garbage from '../../assets/images/logos/50.png';
import people from '../../assets/images/logos/51.png';
import volunteer from '../../assets/images/logos/volunteer.png';
import community from '../../assets/images/logos/52.png';
import { formatIndianNumber } from '../../utils/helper';

export default function MapComponent({
  selectedState,
  setSelectedState,
  states,
  setSelectedStateName,
  selectedStateName,
  mapCount,
  post15,
  topCtuAreawise,
  topCtuIdentified,
  topGarbageCleaned,
  leaderboardData,
  ctuIdentified,
  totalCtuJoinedAdopted,
  bigNumbers,
}) {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const screenWidth = window.innerWidth;

  const logoMapping = [
    { icon: airport, area: 'Airports and surrounding areas' },
    { icon: camera, area: 'Tourist locations' },
    { icon: education, area: 'Areas surrounding schools and colleges' },
    { icon: gaushala, area: 'Gaushalas' },
    { icon: house, area: 'Residential areas' },
    { icon: market, area: 'Market spaces' },
    { icon: medic, area: 'Health Institutions and Adjacent Areas' },
    { icon: pray, area: 'Places of worship' },
    { icon: road, area: 'Roadsides (NH, SH, Local)' },
    { icon: river, area: 'Waterbodies' },
    { icon: slum, area: 'Slums' },
    { icon: trainStation, area: 'Railway tracks and stations' },
    { icon: work, area: 'Areas surrounding public & private offices' },
    { icon: zoo, area: 'Zoos and wildlife areas' },
    { icon: trainStation, area: 'Bus stands and toll plaza' },
  ];

  const getIconByAreaType = (areaType) => {
    const match = logoMapping.find((logo) => logo.area === areaType);
    return match ? match.icon : landIcon; // Default to landIcon if no match is found
  };

  // console.log('leaderboardData', leaderboardData);
  return (
    <div className="container-fluid flag-cont py-5">
      <img
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          objectFit: 'contain',
          // filter: 'blur(2px)',
          // zIndex: -1,
        }}
        src={screenWidth > 767 ? FlagBg : PhoneFlagBg}
        alt="flag"
      />

      <div
        className="container-fluid"
        style={{
          width: '100%',
          height: '100%',
          position: 'relative',
          zIndex: 1,
          alignItems: 'center',
          justifyContent: 'center',
          // display: 'flex',
        }}
      >
        <div
          className="row d-flex align-items-center justify-content-center"
          style={{ paddngTop: '5%' }}
        >
          <div className="col-md-6">
            <Map
              states={states}
              selectedState={selectedState}
              selectedStateName={selectedStateName}
              setSelectedState={setSelectedState}
              setSelectedStateName={setSelectedStateName}
              mapCount={mapCount}
              post15={post15}
            />
          </div>
          <div className="col-md-6">
            <div className="container">
              {/* <div className="box">
                <div className="row d-flex align-items-center justify-content-center">
                  <div className="col-4 text-center">
                    <h3 className="flag-card-head">Total CTUs Identified</h3>
                    {bigNumbers?.ctu_identified_count ? (
                      <h1 className="flag-card-num">{bigNumbers?.ctu_identified_count}</h1>
                    ) : (
                      <Loader size={20} color="#2938a9" />
                    )}
                  </div>
                  <div className="col-4 text-center">
                    <h3 className="flag-card-head">Ongoing CTUs activities</h3>
                    {bigNumbers?.ongoing_ctu_activities ? (
                      <h1 className="flag-card-num">{bigNumbers?.ongoing_ctu_activities}</h1>
                    ) : (
                      <Loader size={20} color="#2938a9" />
                    )}
                  </div>
                  <div className="col-4 text-center">
                    <h3 className="flag-card-head">Total CTUs Transformed</h3>
                    {bigNumbers?.ctu_cleaned_count ? (
                      <h1 className="flag-card-num">{bigNumbers?.ctu_cleaned_count}</h1>
                    ) : (
                      <Loader size={20} color="#2938a9" />
                    )}
                  </div>
                </div>
              </div> */}

              <Grid
                container
                spacing={2}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Grid item xs={12}>
                  <Typography className="main-heading text-center">
                    Key Transformed CTU Areas
                  </Typography>
                </Grid>

                {topCtuAreawise?.map((item, index) => (
                  <Grid item xs={12} md={6}>
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Grid
                          item
                          xs={4}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'end',
                          }}
                        >
                          <img
                            className="img-fluid clean_logo"
                            src={getIconByAreaType(item?.area_type)}
                            alt="target"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={8}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'start',
                          }}
                        >
                          <Box
                            sx={{
                              textAlign: 'start',
                              ml: 2,
                            }}
                          >
                            <Typography
                              variant="h3"
                              className="target_units"
                              sx={{ color: '#3C7A16' }}
                            >
                              {formatIndianNumber(item?.event_count_area_type)}
                            </Typography>
                            <Typography variant="p" className="target_units_sub">
                              {item?.area_type}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>

              <div className="row  d-flex align-items-center justify-content-center mt-5">
                <div className="col-12">
                  <Typography className="main-heading text-center">
                    Statewise CTUs Transformed
                  </Typography>
                </div>

                {leaderboardData?.length > 0 &&
                  leaderboardData.map((item, index) => (
                    <div className="col-12 my-3">
                      <Card className="leaderboard_card">
                        <Card.Body>
                          <div className="row d-flex align-items-center justify-content-center">
                            {/* <div className="col-2 justify-content-center">
                              <Typography className="leaderboard_card_head">{index + 1}</Typography>
                            </div> */}
                            <div className="col-6 text-center">
                              <Typography
                                className="flag-card-head"
                                sx={{
                                  fontSize: '18px',
                                  color: '#474747',
                                  fontFamily: 'Roboto, sans-serif',
                                  fontWeight: 'medium',
                                }}
                              >
                                {item?.state_name}
                              </Typography>
                            </div>
                            <div className="col-6 text-center">
                              <Typography
                                className="flag-card-head"
                                sx={{
                                  fontSize: '24px',
                                  color: '#00631C',
                                  fontFamily: 'Roboto, sans-serif',
                                  fontWeight: 'bold',
                                }}
                              >
                                {formatIndianNumber(item?.cleaned_count)}
                              </Typography>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  ))}
                {/* {leaderboardData?.length > 0 && leaderboardData[0]?.state_name && (
                  <div className="col-12 my-3 position-relative">
                    <img
                      src={GoldIcon}
                      alt="leaderboard"
                      className="img-fluid position-absolute start-30 top-50"
                      style={{ transform: 'translate(7%, -40%)', zIndex: 1 }}
                    />
                    <Card className="leaderboard_card">
                      <Card.Body>
                        <div className="row d-flex align-items-center justify-content-center">
                          <div className="col-2"></div>
                          <div className="col-6 text-center">
                            <Typography className="flag-card-head">
                              {leaderboardData[0]?.state_name}
                            </Typography>
                          </div>
                          <div className="col-4 text-center">
                            <Typography className="flag-card-head">
                              {leaderboardData[0]?.cleaned_count}
                            </Typography>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                )}
                {leaderboardData?.length > 1 && leaderboardData[1]?.state_name && (
                  <div className="col-12 my-3 position-relative">
                    <img
                      src={SilverIcon}
                      alt="leaderboard"
                      className="img-fluid position-absolute start-30 top-50"
                      style={{ transform: 'translate(7%, -40%)', zIndex: 1 }}
                    />
                    <Card className="leaderboard_card">
                      <Card.Body>
                        <div className="row d-flex align-items-center justify-content-center">
                          <div className="col-2"></div>
                          <div className="col-6 text-center">
                            <Typography className="flag-card-head">
                              {leaderboardData[1]?.state_name}
                            </Typography>
                          </div>
                          <div className="col-4 text-center">
                            <Typography className="flag-card-head">
                              {leaderboardData[1]?.cleaned_count}
                            </Typography>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                )}
                {leaderboardData?.length > 2 && leaderboardData[2]?.state_name && (
                  <div className="col-12 my-3 position-relative">
                    <img
                      src={BronzeIcon}
                      alt="leaderboard"
                      className="img-fluid position-absolute start-30 top-50"
                      style={{ transform: 'translate(14%, -40%)', zIndex: 1 }}
                    />
                    <Card className="leaderboard_card">
                      <Card.Body>
                        <div className="row d-flex align-items-center justify-content-center">
                          <div className="col-2"></div>
                          <div className="col-6 text-center">
                            <Typography className="flag-card-head">
                              {leaderboardData[2]?.state_name}
                            </Typography>
                          </div>
                          <div className="col-4 text-center">
                            <Typography className="flag-card-head">
                              {leaderboardData[2]?.cleaned_count}
                            </Typography>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                )}
                {leaderboardData?.length > 3 && leaderboardData[3]?.state_name && (
                  <div className="col-12 my-3">
                    <Card className="leaderboard_card">
                      <Card.Body>
                        <div className="row d-flex align-items-center justify-content-center">
                          <div className="col-2 justify-content-center">
                            <Typography className="leaderboard_card_head">4</Typography>
                          </div>
                          <div className="col-6 text-center">
                            <Typography className="flag-card-head">
                              {leaderboardData[3]?.state_name}
                            </Typography>
                          </div>
                          <div className="col-4 text-center">
                            <Typography className="flag-card-head">
                              {' '}
                              {leaderboardData[3]?.cleaned_count}
                            </Typography>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                )}
                {leaderboardData?.length > 4 && leaderboardData[4]?.state_name && (
                  <div className="col-12 my-3">
                    <Card className="leaderboard_card">
                      <Card.Body>
                        <div className="row d-flex align-items-center justify-content-center">
                          <div className="col-2 justify-content-center">
                            <Typography className="leaderboard_card_head">5</Typography>
                          </div>
                          <div className="col-6 text-center">
                            <Typography className="flag-card-head">
                              {leaderboardData[4]?.state_name}
                            </Typography>
                          </div>
                          <div className="col-4 text-center">
                            <Typography className="flag-card-head">
                              {' '}
                              {leaderboardData[4]?.cleaned_count}
                            </Typography>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                )} */}
              </div>
              {/* )} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
