import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography, Card, CardContent, Button } from '@mui/material';
import styled from '@mui/material/styles/styled';
import Paper from '@mui/material/Paper';
import { useLocation, useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import landIcon from '../../assets/images/logos/land.png';

import sports from '../../assets/images/logos/12.png';
import fashion from '../../assets/images/logos/26.png';
import rrr from '../../assets/images/logos/14.png';
import craftsmanship from '../../assets/images/logos/28.png';
import martialarts from '../../assets/images/logos/27.png';
import samvad from '../../assets/images/logos/48.png';
import agriculture from '../../assets/images/logos/29.png';
import art from '../../assets/images/logos/17.png';
import art_events from '../../assets/images/logos/8.png';
import pledges from '../../assets/images/logos/1.png';
import selfie from '../../assets/images/logos/47.png';
import mass_scale from '../../assets/images/logos/33.png';
import cultural_displays from '../../assets/images/logos/30.png';
import film from '../../assets/images/logos/21.png';
import marathons from '../../assets/images/logos/3.png';
import fairs from '../../assets/images/logos/19.png';
import crafts from '../../assets/images/logos/24.png';
import dance from '../../assets/images/logos/15.png';
import human_chains from '../../assets/images/logos/5.png';
import music from '../../assets/images/logos/16.png';
import language from '../../assets/images/logos/25.png';
import theatre from '../../assets/images/logos/18.png';
import heritage_tours from '../../assets/images/logos/23.png';
import youth from '../../assets/images/logos/7.png';
import food_streets from '../../assets/images/logos/32.png';
import quizzes from '../../assets/images/logos/49.png';
import gramsabhas from '../../assets/images/logos/6.png';
import literature from '../../assets/images/logos/22.png';
import door_to_door from '../../assets/images/logos/13.png';
import safaimitra from '../../assets/images/logos/43.png';
import sbm from '../../assets/images/logos/11.png';
import art_installations from '../../assets/images/logos/31.png';
import chaupal from '../../assets/images/logos/46.png';
import workshops from '../../assets/images/logos/20.png';
import cyclothons from '../../assets/images/logos/4.png';
import refurbish_sbm from '../../assets/images/logos/9.png';
import plant from '../../assets/images/logos/10.gif';
import { formatIndianNumber } from '../../utils/helper';
import { getJanBhagidariData } from '../../api';

export default function EventPlanned({ eventHighlights }) {
  const { t } = useTranslation();
  const { location } = useLocation();
  const screenWidth = window.innerWidth;
  const [topEvents, setTopEvents] = useState([]);
  const [ekPedEvent, setEkPedEvent] = useState(null);
  const [janBhagidariData, setJanBhagidariData] = useState({});

  const logoMapping = [
    { icon: fashion, area: 'Fashion Shows', id: 26 },
    { icon: sports, area: 'SBM Sports league', id: 12 },
    { icon: rrr, area: 'RRR activities', id: 14 },
    { icon: craftsmanship, area: 'Craftsmanship Workshops', id: 28 },
    { icon: martialarts, area: 'Martial Arts and Traditional Sports', id: 27 },
    { icon: samvad, area: 'Swachhata Samvad', id: 48 },
    { icon: agriculture, area: 'Agricultural Practices', id: 29 },
    { icon: art, area: 'Art Exhibitions', id: 17 },
    { icon: art_events, area: 'Waste to art events', id: 8 },
    { icon: pledges, area: 'Swachhata pledges', id: 1 },
    { icon: selfie, area: 'Selfie point', id: 47 },
    { icon: mass_scale, area: 'Mass scale cleanliness drives', id: 33 },
    { icon: cultural_displays, area: 'Interactive Cultural Displays', id: 30 },
    { icon: film, area: 'Film Screenings', id: 21 },
    { icon: marathons, area: 'Marathons', id: 3 },
    { icon: fairs, area: 'Fairs', id: 19 },
    { icon: cultural_displays, area: 'CTU Identification', id: 34 },
    { icon: crafts, area: 'Crafts and Handicrafts', id: 24 },
    { icon: dance, area: 'Traditional Dance Performances', id: 15 },
    { icon: human_chains, area: 'Human chains', id: 5 },
    { icon: music, area: 'Music and Singing', id: 16 },
    { icon: language, area: 'Language Classes', id: 25 },
    { icon: theatre, area: 'Theater and Drama', id: 18 },
    { icon: heritage_tours, area: 'Cultural Heritage Tours', id: 23 },
    { icon: youth, area: 'Youth connect', id: 7 },
    { icon: food_streets, area: 'Swachh Food Streets Initiative', id: 32 },
    { icon: quizzes, area: 'Swachhata Quizzes', id: 49 },
    { icon: gramsabhas, area: 'Gram Sabhas', id: 6 },
    { icon: literature, area: 'Storytelling and Literature Events', id: 22 },
    { icon: door_to_door, area: 'Door to Door awareness', id: 13 },
    { icon: safaimitra, area: 'SafaiMitra Suraksha Shivirs', id: 43 },
    { icon: art_installations, area: 'Waste to Art Installations', id: 31 },
    { icon: plant, area: 'Ek ped maa ke naam', id: 10 },
    { icon: sbm, area: 'Competition for SBM', id: 11 },
    { icon: chaupal, area: 'Swachhata Chaupal', id: 46 },
    { icon: workshops, area: 'Cultural Workshops', id: 20 },
    { icon: cyclothons, area: 'Cyclothons', id: 4 },
    { icon: refurbish_sbm, area: 'Inauguration/ Refurbish SBM Assets', id: 9 },
  ];

  const getIconByAreaType = (event_name_id) => {
    const match = logoMapping.find((logo) => logo.id == event_name_id);
    return match ? match.icon : landIcon; // Default to landIcon if no match is found
  };

  const getData = async () => {
    const res = await getJanBhagidariData();
    console.log(res);
    if (res.status === 200) {
      console.log(res.data[0]);
      setJanBhagidariData(res.data[0]);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Box
      sx={{
        width: '100%',
        margin: 'auto',
        textAlign: 'center',
      }}
    >
      <Grid
        container
        spacing={2}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          //   backgroundImage: `url(${TargetBg})`,
          background:
            'radial-gradient(circle, rgba(246,214,241,1) 0%, rgba(241,225,219,1) 49%, rgba(212,219,249,1) 100%)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          minHeight: '65vh',
          paddingTop: '20px',
          paddingBottom: '20px',
        }}
      >
        <Grid item xs={12}>
          <Typography className="main-heading">Swachhata Mein Jan Bhaagidari</Typography>
        </Grid>

        <Grid
          container
          spacing={2}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '90%',
          }}
        >
          <Grid item xs={12} md={4}>
            <Grid item xs={12}>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: '10px',
                  }}
                >
                  <Grid
                    item
                    xs={4}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'end',
                    }}
                  >
                    <img className="img-fluid clean_logo" src={cyclothons} alt="target" />
                  </Grid>
                  <Grid
                    item
                    xs={8}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'start',
                    }}
                  >
                    <Box
                      sx={{
                        textAlign: 'start',
                        ml: 2,
                      }}
                    >
                      <Typography variant="h3" className="target_units" sx={{ color: '#3C7A16' }}>
                        {/* {formatIndianNumber(janBhagidariData?.Cyclothons)} */}
                        {formatIndianNumber(Number(janBhagidariData?.Cyclothons))}
                      </Typography>
                      <Typography
                        variant="p"
                        className="target_units_sub"
                        style={{
                          textTransform: 'capitalize',
                        }}
                      >
                        Cyclothons
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: '10px',
                  }}
                >
                  <Grid
                    item
                    xs={4}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'end',
                    }}
                  >
                    <img className="img-fluid clean_logo" src={youth} alt="target" />
                  </Grid>
                  <Grid
                    item
                    xs={8}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'start',
                    }}
                  >
                    <Box
                      sx={{
                        textAlign: 'start',
                        ml: 2,
                      }}
                    >
                      <Typography variant="h3" className="target_units" sx={{ color: '#3C7A16' }}>
                        {formatIndianNumber(Number(janBhagidariData?.Youth_connect))}
                      </Typography>
                      <Typography
                        variant="p"
                        className="target_units_sub"
                        style={{
                          textTransform: 'capitalize',
                        }}
                      >
                        Youth Connect
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: '10px',
                  }}
                >
                  <Grid
                    item
                    xs={4}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'end',
                    }}
                  >
                    <img className="img-fluid clean_logo" src={pledges} alt="target" />
                  </Grid>
                  <Grid
                    item
                    xs={8}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'start',
                    }}
                  >
                    <Box
                      sx={{
                        textAlign: 'start',
                        ml: 2,
                      }}
                    >
                      <Typography variant="h3" className="target_units" sx={{ color: '#3C7A16' }}>
                        {formatIndianNumber(Number(janBhagidariData?.Swachhata_pledges))}
                      </Typography>
                      <Typography
                        variant="p"
                        className="target_units_sub"
                        style={{
                          textTransform: 'capitalize',
                        }}
                      >
                        Swachhata Pledges
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box>
              <img className="img-fluid plant_logo" src={plant} alt="Ek ped maa ke naam" />
              <Typography variant="h3" className="target_units" sx={{ color: '#3C7A16' }}>
                {formatIndianNumber(Number(janBhagidariData?.Ek_ped_maa_ke_naam))} <br /> Trees
                Planted
              </Typography>
              {/* <Typography
                variant="p"
                className="target_units_sub"
                style={{
                  textTransform: 'capitalize',
                }}
              >
                Ek ped maa ke naam
              </Typography> */}
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid item xs={12}>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: '10px',
                  }}
                >
                  <Grid
                    item
                    xs={8}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'end',
                    }}
                  >
                    <Box
                      sx={{
                        textAlign: 'end',
                        ml: 2,
                      }}
                    >
                      <Typography variant="h3" className="target_units" sx={{ color: '#3C7A16' }}>
                      {formatIndianNumber(Number(janBhagidariData?.Swachh_Food_Streets_Initiative))}
                      </Typography>
                      <Typography
                        variant="p"
                        className="target_units_sub"
                        style={{
                          textTransform: 'capitalize',
                        }}
                      >
                        Swachh Food Streets
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <img className="img-fluid clean_logo" src={food_streets} alt="target" />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: '10px',
                  }}
                >
                  <Grid
                    item
                    xs={8}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'end',
                    }}
                  >
                    <Box
                      sx={{
                        textAlign: 'end',
                        ml: 2,
                      }}
                    >
                      <Typography variant="h3" className="target_units" sx={{ color: '#3C7A16' }}>
                      {formatIndianNumber(Number(janBhagidariData?.Swachh_Bharat_Cultural_fests))}
                      </Typography>
                      <Typography
                        variant="p"
                        className="target_units_sub"
                        style={{
                          textTransform: 'capitalize',
                        }}
                      >
                        Swachh Bharat Cultural Fests
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <img className="img-fluid clean_logo" src={cultural_displays} alt="target" />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: '10px',
                  }}
                >
                  <Grid
                    item
                    xs={8}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'end',
                    }}
                  >
                    <Box
                      sx={{
                        textAlign: 'end',
                        ml: 2,
                      }}
                    >
                      <Typography variant="h3" className="target_units" sx={{ color: '#3C7A16' }}>
                      {formatIndianNumber(Number(janBhagidariData?.Waste_to_Art_Installations))}
                      </Typography>
                      <Typography
                        variant="p"
                        className="target_units_sub"
                        style={{
                          textTransform: 'capitalize',
                        }}
                      >
                        Waste to art events
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <img className="img-fluid clean_logo" src={art_installations} alt="target" />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid item xs={12} md={4}>
            <Grid item xs={12}>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: '10px',
                  }}
                >
                  <Grid
                    item
                    xs={4}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'end',
                    }}
                  >
                    <img className="img-fluid clean_logo" src={mass_scale} alt="target" />
                  </Grid>
                  <Grid
                    item
                    xs={8}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'start',
                    }}
                  >
                    <Box
                      sx={{
                        textAlign: 'start',
                        ml: 2,
                      }}
                    >
                      <Typography variant="h3" className="target_units" sx={{ color: '#3C7A16' }}>
                        {cleaninessDrive} sqm area cleaned
                      </Typography>
                      <Typography
                        variant="p"
                        className="target_units_sub"
                        style={{
                          textTransform: 'capitalize',
                        }}
                      >
                        Mass scale cleanliness drives
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sx={{ my: 2 }}>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: '10px',
                  }}
                >
                  <Grid
                    item
                    xs={4}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'end',
                    }}
                  >
                    <img className="img-fluid clean_logo" src={safaimitra} alt="target" />
                  </Grid>
                  <Grid
                    item
                    xs={8}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'start',
                    }}
                  >
                    <Box
                      sx={{
                        textAlign: 'start',
                        ml: 2,
                      }}
                    >
                      <Typography variant="h3" className="target_units" sx={{ color: '#3C7A16' }}>
                        {safaiMitra} safaimitras benefitted
                      </Typography>
                      <Typography
                        variant="p"
                        className="target_units_sub"
                        style={{
                          textTransform: 'capitalize',
                        }}
                      >
                        SafaiMitra Suraksha Shivirs
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid> */}
        </Grid>
      </Grid>
    </Box>
  );
}
